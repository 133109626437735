export const updateDispatchData = (
    name: string,
    value: string,
    setDispatchFirstData: React.Dispatch<React.SetStateAction<any>>,
    setInfoErrorMessages: React.Dispatch<React.SetStateAction<any>>
  ) => {

    setDispatchFirstData((prev:any) => ({
      ...prev,
      [name]: value,
    }));
  

    const fieldsToClear = ["weight", "pickUpAddress", "dropOffAddress"];
  
    if (fieldsToClear.includes(name) && value !== "") {
      setInfoErrorMessages((prev:any) => ({ ...prev, [name]: "" }));
    }
  

    if (name === "weight" && !isNaN(Number(value))) {
      setInfoErrorMessages((prev:any) => ({ ...prev, weight: "" }));
    }
  

    if (name === "dropOffAddress" && value !== "") {
      setDispatchFirstData((prev:any) => ({
        ...prev,
        dropOffLocker: "",
      }));
    }
  };
  