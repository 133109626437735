export const checkDateRange = (date: Date | null): boolean => {
    if (!date) return false; // Return false if the date is null
    
    const selectedDate = new Date(date);
    const selectedDay = selectedDate.getDate();
    const selectedMonth = selectedDate.getMonth(); // Months are 0-indexed in JavaScript
  
    console.log("selectedDate", selectedDate);
    console.log("selectedDay", selectedDay);
    console.log("selectedMonth", selectedMonth);
  
    // Define the current date for reference
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
  
    // Calculate the start of range (25th of the current month)
    const startOfRange = new Date(currentDate.getFullYear(), currentMonth, 25);
  
    // Calculate the end of range (5th of the next month)
    let endOfRange: Date;
    if (currentMonth === 11) {
      // December case
      endOfRange = new Date(currentDate.getFullYear() + 1, 0, 5); // January of next year
    } else {
      endOfRange = new Date(currentDate.getFullYear(), currentMonth + 1, 5);
    }
  
    if (
      (selectedMonth === startOfRange.getMonth() && selectedDay >= 25) ||
      (selectedMonth === endOfRange.getMonth() && selectedDay <= 5)
    ) {
    
      return true;
    }
  
    return false;
  };