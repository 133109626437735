import React from "react";
import { InformationLayout } from "../../InfoLayout";
import { Button } from "../../Button";
import { Input } from "../../form/Input";
import { SectionBack } from "../../SectionBack";
import getSymbolFromCurrency from "currency-symbol-map";
import { getReferralMessage } from "../../../helper/getReferralMessage";

interface Props {
  setShowTerms: React.Dispatch<React.SetStateAction<boolean>>;
  errorCost: string;
  infoErrorMessages: any;
  referralData: any;
  dispatchFirstData: any;
  handleChange: (e: any) => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step: number;
  currencyCode: string;
  costData: any;
  isReferralLoading: boolean;
  setApplyReferral: React.Dispatch<React.SetStateAction<boolean>>;
  getCostReferralData: () => void;
  setTermsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  isMultipleLoading: boolean;
  onProceedToShipment: () => void;
}

const ImportStepThree = ({
  setShowTerms,
  errorCost,
  infoErrorMessages,
  dispatchFirstData,
  handleChange,
  referralData,
  step,
  setStep,
  currencyCode,
  costData,
  isReferralLoading,
  setApplyReferral,
  getCostReferralData,
  setTermsChecked,
  isMultipleLoading,
  onProceedToShipment,
}: Props) => {
  return (
    <div className=" mb-32 ">
      <SectionBack
        imgStr="/images/all-car.svg"
        onClick={() => setStep(step - 1)}
        content=" Import Now"
      />
      <div className="mt-10  w-full px-5 sm:px-10 xl:px-20 md:flex-row flex-col md:space-y-0 space-y-5   flex md:justify-between ">
        <div className=" w-full ">
          <h3 className="md:text-[32px] text-[24px] font-ppmigra text-textColor">
            Smart delivery solution
          </h3>
          <InformationLayout onClick={() => setShowTerms(true)} />
        </div>
        <div className="w-full ">
          {errorCost !== "" && (
            <div className="bg-[#f19797] border-[red]  border max-w-[500px] px-5 py-2 rounded-md">
              <p className="text-white items-center flex text-[14px]  font-onestMedium">
                {errorCost}
              </p>
            </div>
          )}
          <div className="mt-3 space-y-3 max-w-[500px]">
            <p className="text-textGreen font-onestBold text-[16px] ">
              TOTAL COST
            </p>
            <p className="font-onestRegular text-[14px] md:text-[15px]">
              The total estimated cost for your delivery is
            </p>
            <div className="border rounded-[8px] px-3 py-3 border-[#C2C5C2] shadow  drop-shadow-2xl  shadow-shadow">
              {referralData?.status === "00" && referralData?.rate !== "" ? (
                <div className="flex space-x-3">
                  <h2
                    className={`line-through text-red-500 font-onestBold text-[28px] md:text-[34px]`}
                  >
                    {` ${getSymbolFromCurrency(
                      currencyCode
                    )} ${new Intl.NumberFormat().format(
                      Number(costData?.rate)
                    )}`}
                  </h2>
                  <h2 className="font-onestBold text-textGreen text-[28px] md:text-[34px]">
                    {` ${getSymbolFromCurrency(
                      currencyCode
                    )} ${new Intl.NumberFormat().format(
                      Number(referralData?.rate)
                    )}`}
                    <span className="block text-lg text-textGreen">
                      20% drop-off discount applied!
                    </span>
                  </h2>
                </div>
              ) : (
                <h2 className="font-onestBold text-textGreen text-[28px] md:text-[34px]">
                  {getSymbolFromCurrency(currencyCode)}{" "}
                  {new Intl.NumberFormat().format(Number(costData?.rate))}
                </h2>
              )}
            </div>
          </div>
          <div className="flex max-w-[500px] mt-5 items-center space-x-2">
            <Input
              type="text"
              value={dispatchFirstData.referralCode}
              onChange={handleChange}
              label="Referral Code"
              name="referralCode"
              placeholder="Enter referral code"
              labelFor="referralCode"
            />
            <Button
              title="Apply"
              loading={isReferralLoading}
              className="mt-5 border border-btnColor !bg-[#E8FFE5]"
              onClick={() => {
                setApplyReferral(true);
                getCostReferralData();
              }}
            />
          </div>
          {referralData?.status && (
            <p
              className={`${
                referralData?.status === "00"
                  ? "text-green-600"
                  : "text-red-500"
              }text-green-600 text-[12px]`}
            >
              {getReferralMessage(referralData)}
            </p>
          )}

          <div className="mt-3 flex space-x-1">
            <input
              type="checkbox"
              required={true}
              className="cursor-pointer"
              onChange={(e: any) => setTermsChecked(e.target.checked)}
            />

            <div>
              <p className="text-[14px] font-onest text-grey">
                By ticking the checkbox, you have accepted our
                <span
                  className="underline text-textColor ml-1 cursor-pointer"
                  onClick={() => setShowTerms(true)}
                >
                  Terms and Conditions.
                </span>
              </p>
            </div>
          </div>
          {infoErrorMessages.terms && (
            <p className="text-red-500 text-[12px]">
              {infoErrorMessages.terms}
            </p>
          )}
          <div className="mt-3 space-y-2 max-w-[500px] ">
            <Button
              title="Continue with your Shipment"
              className="!py-3"
              loading={isMultipleLoading}
              onClick={onProceedToShipment}
            />
            <Button
              title="Back"
              disabled={isMultipleLoading}
              className="!py-3 !bg-transparent"
              onClick={() => setStep(step - 1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportStepThree;
