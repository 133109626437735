export const checkOrderStatus = (
    costData: any,
    costDataResult: any,
    intlCostData: any,
    setOrderStatus: (status: boolean) => void,
    setErrorCost: (message: string) => void
  ) => {
    const isOrderLocked =
      costDataResult?.orderLocked === "YF" ||
      costDataResult?.orderLocked === "YS" ||
      costDataResult?.orderLocked === "Y";
  
    const isDiscountZero =
      costData?.discountedRate === "0.00" ||
      intlCostData?.discountedRate === "0.00" ||
      Number(costData?.discountedRate) === 0 ||
      Number(intlCostData?.discountedRate) === 0;
  
    const hasErrorStatus =
      intlCostData?.status === "Error" ||
      (costDataResult?.status === "Error" && costDataResult?.orderLocked === "Y") ||
      (intlCostData?.status === "Error" && intlCostData?.orderLocked === "Y");
  
    if (isOrderLocked || isDiscountZero || hasErrorStatus) {
      setOrderStatus(false);
    }
  
    if (isDiscountZero) {
      const timer = setTimeout(() => {
        setErrorCost("Network Error!");
      }, 3000);
      setOrderStatus(false);
      return () => clearTimeout(timer);
    }
  };

