export const handleDispatchOptionClick = (
    option: any, state: any, queryDeliverytype:string,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setPickUpState: React.Dispatch<React.SetStateAction<any>>,
    setInfoErrorMessages: React.Dispatch<React.SetStateAction<any>>,
    setDispatchFirstData: React.Dispatch<React.SetStateAction<any>>,
    setQuery: React.Dispatch<React.SetStateAction<string>>,

) => {
    const isLocal = queryDeliverytype?.toLowerCase() === "local";
    const locationKey = isLocal ? "location" : "state";
  
    
    setInfoErrorMessages((prev:any) => ({
      ...prev,
      pickUpZone: option[locationKey] ? "" : "Pickup zone is required",
    }));
  

    setIsOpen(false);
    setPickUpState(state);
    setQuery(option[locationKey]);
  

    setDispatchFirstData((prev:any) => ({
      ...prev,
      pickUpZone: option.zoneid || option.id,
      pickUpLocationId: option.id,
      pickUpLocationName: option[locationKey],
    }));
  };
  

    // if (queryDeliverytype?.toLowerCase() === "local") {
    //   if (option.location === "") {
    //     setInfoErrorMessages((prev) => ({
    //       ...prev,
    //       pickUpZone: "Pickup zone is required",
    //     }));
    //   }
    //   if (option.location !== "") {
    //     setInfoErrorMessages((prev) => ({
    //       ...prev,
    //       pickUpZone: "",
    //     }));
    //   }
    //   setPickUpState(state);
    //   setQuery(option.location);
    //   setIsOpen(false);
    //   setDispatchFirstData((prev) => ({
    //     ...prev,
    //     pickUpZone: option.zoneid,
    //   }));
    //   setDispatchFirstData((prev) => ({
    //     ...prev,
    //     pickUpLocationId: option.id,
    //   }));
    //   setDispatchFirstData((prev) => ({
    //     ...prev,
    //     pickUpLocationName: option.location,
    //   }));
    // } else {
    //   if (option.state === "") {
    //     setInfoErrorMessages((prev) => ({
    //       ...prev,
    //       pickUpZone: "Pickup zone is required",
    //     }));
    //   }
    //   if (option.state !== "") {
    //     setInfoErrorMessages((prev) => ({
    //       ...prev,
    //       pickUpZone: "",
    //     }));
    //   }
    //   setIsOpen(false);
    //   setQuery(option.state);
    //   setDispatchFirstData((prev) => ({
    //     ...prev,
    //     pickUpZone: option.id,
    //   }));
    //   setDispatchFirstData((prev) => ({
    //     ...prev,
    //     pickUpLocationId: option.id,
    //   }));
    //   setDispatchFirstData((prev) => ({
    //     ...prev,
    //     pickUpLocationName: option.state,
    //   }));
    // }