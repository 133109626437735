import React from "react";
import { InformationLayout } from "../../InfoLayout";
import { Button } from "../../Button";
import { Input } from "../../form/Input";
import { SectionBack } from "../../SectionBack";
import { TextArea } from "../../form/textArea";
import ToggleButton from "../../ToggleButton";

interface Props {
  setShowTerms: React.Dispatch<React.SetStateAction<boolean>>;
  errorCost: string;
  infoErrorMessages: any;
  dispatchFirstData: any;
  handleChangeTwo: (e: any) => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step: number;
  currencyCode: string;
  onFinishStepTwo: () => void;
  handleFileChange: (e: any) => void;
}

const ImportStepTwo = ({
  setShowTerms,
  errorCost,
  infoErrorMessages,
  dispatchFirstData,
  handleChangeTwo,
  step,
  setStep,
  currencyCode,
  onFinishStepTwo,
  handleFileChange,
}: Props) => {
  return (
    <div className=" mb-32 ">
      <SectionBack
        imgStr="/images/all-car.svg"
        onClick={() => setStep(step - 1)}
        content=" Import Now"
      />
      <div className="mt-10  w-full px-5 sm:px-10 xl:px-20 md:flex-row flex-col md:space-y-0 space-y-5   flex md:justify-between ">
        <div className=" w-full ">
          <h3 className="md:text-[32px] text-[24px] font-ppmigra text-textColor">
            Smart delivery solution
          </h3>
          <InformationLayout onClick={() => setShowTerms(true)} />
        </div>
        <div className="w-full ">
          {errorCost !== "" && (
            <div className="bg-[#f19797] border-[red]  border max-w-[500px] px-5 py-2 rounded-md">
              <p className="text-white items-center flex text-[14px]  font-onestMedium">
                {errorCost}
              </p>
            </div>
          )}
          <div className="mt-2 space-y-3 max-w-[500px]">
            <p className="text-textGreen font-onestBold text-[16px] mt-3">
              SENDERS INFORMATION
            </p>
            <div>
              <Input
                type="text"
                name="senderName"
                label="Sender's Name"
                placeholder="Enter name"
                labelFor="sendersName"
                value={dispatchFirstData?.senderName}
                isRequired
                onChange={handleChangeTwo}
              />
              {infoErrorMessages.senderName && (
                <p className="text-red-500 text-[12px]">
                  {infoErrorMessages.senderName}
                </p>
              )}
            </div>
            <div>
              <Input
                type="text"
                value={dispatchFirstData?.senderPhone}
                name="senderPhone"
                label="Sender’s Phone number"
                placeholder="Enter phone number"
                labelFor="phoneNumber"
                onChange={handleChangeTwo}
                isRequired
              />
              {infoErrorMessages.senderPhone && (
                <p className="text-red-500 text-[12px]">
                  {infoErrorMessages.senderPhone}
                </p>
              )}
            </div>
            <div>
              <Input
                type="text"
                value={dispatchFirstData?.senderAltPhone}
                name="senderAltPhone"
                label="Sender’s Alternative Phone number"
                placeholder="Enter phone number"
                labelFor="phoneNumber"
                onChange={handleChangeTwo}
              />
              {infoErrorMessages.senderAltPhone && (
                <p className="text-red-500 text-[12px]">
                  {infoErrorMessages.senderAltPhone}
                </p>
              )}
            </div>

            <div>
              <Input
                type="email"
                name="senderEmail"
                value={dispatchFirstData?.senderEmail}
                label="Sender’s Email"
                placeholder="Enter email address"
                labelFor="senderEmail"
                onChange={handleChangeTwo}
                isRequired
              />
              {infoErrorMessages.senderEmail && (
                <p className="text-red-500 text-[12px]">
                  {infoErrorMessages.senderEmail}
                </p>
              )}
            </div>
          </div>
          <div className="mt-8 space-y-3 max-w-[500px]">
            <p className="text-textGreen font-onestBold text-[16px] ">
              RECIPIENT INFORMATION
            </p>

            <div>
              <Input
                name="recipientName"
                type="text"
                label="Recipient's Name"
                value={dispatchFirstData?.recipientName}
                placeholder="Enter name"
                labelFor="recipientName"
                onChange={handleChangeTwo}
                isRequired
              />
              {infoErrorMessages.recipientName && (
                <p className="text-red-500 text-[12px]">
                  {infoErrorMessages.recipientName}
                </p>
              )}
            </div>
            <div>
              <Input
                name="recipientEmail"
                type="email"
                label="Recipient's Email"
                isRequired
                value={dispatchFirstData?.recipientEmail}
                placeholder="Enter email"
                labelFor="recipientEmail"
                onChange={handleChangeTwo}
              />
              {infoErrorMessages.recipientEmail && (
                <p className="text-red-500 text-[12px]">
                  {infoErrorMessages.recipientEmail}
                </p>
              )}
            </div>
            <div>
              <Input
                type="text"
                value={dispatchFirstData?.recipientPhone}
                name="recipientPhone"
                label="Recipient's Phone number"
                placeholder="Enter phone number"
                labelFor="recipientPhone"
                isRequired
                onChange={handleChangeTwo}
              />
              {infoErrorMessages.recipientPhone && (
                <p className="text-red-500 text-[12px]">
                  {infoErrorMessages.recipientPhone}
                </p>
              )}
            </div>
          </div>
          <div className="mt-8 space-y-3 max-w-[500px]">
            <p className="text-textGreen font-onestBold text-[16px] ">
              PACKAGE DETAILS
            </p>

            <div>
              <TextArea
                label="Item Description"
                placeholder="Enter description"
                labelFor="description"
                name="itemDescription"
                value={dispatchFirstData?.itemDescription}
                onChange={handleChangeTwo}
                isRequired
              />

              <ToggleButton
                data={dispatchFirstData.fragile}
                handleFn={handleChangeTwo}
              />

              {infoErrorMessages.itemDescription && (
                <p className="text-red-500 text-[12px]">
                  {infoErrorMessages.itemDescription}
                </p>
              )}
            </div>
            <div>
              <Input
                name="quantity"
                type="text"
                label="Quantity"
                value={dispatchFirstData?.quantity}
                placeholder="Enter quantity"
                labelFor="quantity"
                isRequired
                onChange={handleChangeTwo}
              />
              {infoErrorMessages.quantity && (
                <p className="text-red-500 text-[12px]">
                  {infoErrorMessages.quantity}
                </p>
              )}
            </div>
            <div>
              <Input
                type="text"
                name="cost"
                label={`Value of Item in  ${currencyCode}`}
                placeholder="Enter value"
                labelFor="cost"
                isRequired
                value={dispatchFirstData?.cost}
                onChange={handleChangeTwo}
              />
              {infoErrorMessages.cost && (
                <p className="text-red-500 text-[12px]">
                  {infoErrorMessages.cost}
                </p>
              )}
            </div>
            {Number(dispatchFirstData?.cost) >=
              Number(dispatchFirstData?.importAddress) && (
              <div>
                <Input
                  type="file"
                  name="invoice"
                  label="Invoice"
                  labelFor="Invoice"
                  isRequired
                  accept=".jpg, .jpeg, .png, .pdf"
                  placeholder={dispatchFirstData.fileNames}
                  onChange={handleFileChange}
                  file={dispatchFirstData.fileNames}
                />
                {infoErrorMessages.file && (
                  <p className="text-red-500 text-[12px]">
                    {infoErrorMessages.file}
                  </p>
                )}
              </div>
            )}
          </div>
          <div className="mt-10 space-y-2 max-w-[500px] ">
            <Button
              title="Next"
              className="!py-3"
              onClick={onFinishStepTwo}
              loading={false}
            />
            <Button
              title="Back"
              className="!py-3 !bg-transparent"
              onClick={() => setStep(step - 1)}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportStepTwo;
