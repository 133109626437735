export const clearErrorMessage = (
    name: string,
    value: any,
    setInfoErrorMessages: React.Dispatch<React.SetStateAction<any>>
  ) => {
    const fieldsToCheck = [
      "senderPhone",
      "senderAltPhone",
      "recipientPhone",
      "quantity",
      "cost",
    ];
  
    if (fieldsToCheck.includes(name) && value !== "" && !isNaN(value)) {
      setInfoErrorMessages((prev:any) => ({ ...prev, [name]: "" }));
      return;
    }
  
    const textFields = [
      "senderName",
      "senderEmail",
      "recipientEmail",
      "recipientName",
      "itemDescription",
    ];
  
    if (textFields.includes(name) && value !== "") {
      setInfoErrorMessages((prev:any) => ({ ...prev, [name]: "" }));
    }
  };
  