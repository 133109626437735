export const validateRequiredField = (
    fieldValue: string,
    fieldName: "dropOffLocker" | "recipientEmail",
    errorMessage: string,
    condition: boolean,
    setInfoErrorMessages: React.Dispatch<React.SetStateAction<any>>
  ) => {
    setInfoErrorMessages((prev:any) => ({
      ...prev,
      [fieldName]: condition && fieldValue === "" ? errorMessage : "",
    }));
  };
  