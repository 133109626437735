export const validateDispatchData = (
    dispatchFirstData: Record<string, any>,
    useSmartLocker: boolean,
    setInfoErrorMessages: React.Dispatch<React.SetStateAction<any>>
  ) => {
    setInfoErrorMessages((prev:any) => {
        const errors: Record<string, string> = { ...prev };
    
        if (useSmartLocker) {
          errors.dropOffLocker = dispatchFirstData?.dropOffLockerAddress
            ? ""
            : "Select a drop off address";
        }
    
        errors.pickUpZone = dispatchFirstData?.pickUpZone
          ? ""
          : "Pick up zone is required";
    
        errors.dropOffZone = dispatchFirstData?.dropOffZone
          ? ""
          : "Drop off zone is required";
    
        errors.pickUpAddress = dispatchFirstData?.pickUpAddress
          ? ""
          : "Pick up address is required";
    
        errors.pickUpDate = dispatchFirstData?.pickUpDate
          ? ""
          : "Pick up date is required";
    
        if (!useSmartLocker) {
          errors.dropOffAddress = dispatchFirstData?.dropOffAddress
            ? ""
            : "Drop off address is required";
        }
    
        if (useSmartLocker) {
          errors.dropOffLocker = dispatchFirstData?.dropOffLocker
            ? ""
            : "Select a drop off address is required";
        }
    
        return errors
    });
//    if (dispatchFirstData?.dropOffLockerAddress === "" && useSmartLocker) {
//       setInfoErrorMessages((prev:any) => ({
//         ...prev,
//         dropOffLocker: "Select a drop off address",
//       }));
//     } else if (
//       dispatchFirstData?.dropOffLockerAddress !== "" &&
//       useSmartLocker
//     ) {
//       setInfoErrorMessages((prev:any) => ({
//         ...prev,
//         dropOffLocker: "",
//       }));
//     }
//     if (dispatchFirstData?.pickUpZone !== "") {
//       setInfoErrorMessages((prev:any) => ({
//         ...prev,
//         pickUpZone: "",
//       }));
//     } else if (dispatchFirstData?.pickUpZone === "") {
//       setInfoErrorMessages((prev:any) => ({
//         ...prev,
//         pickUpZone: "Pick up zone is required",
//       }));
//     }

//     if (dispatchFirstData?.dropOffZone !== "") {
//       setInfoErrorMessages((prev:any) => ({
//         ...prev,
//         dropOffZone: "",
//       }));
//     } else if (dispatchFirstData?.dropOffZone === "") {
//       setInfoErrorMessages((prev:any) => ({
//         ...prev,
//         dropOffZone: "Drop off zone is required",
//       }));
//     }

//     if (dispatchFirstData?.pickUpAddress !== "") {
//       setInfoErrorMessages((prev:any) => ({
//         ...prev,
//         pickUpAddress: "",
//       }));
//     } else if (dispatchFirstData?.pickUpAddress === "") {
//       setInfoErrorMessages((prev:any) => ({
//         ...prev,
//         pickUpAddress: "Pick up address is required",
//       }));
//     }

//     if (dispatchFirstData?.pickUpDate !== "") {
//       setInfoErrorMessages((prev:any) => ({
//         ...prev,
//         pickUpDate: "",
//       }));
//     } else if (dispatchFirstData?.pickUpDate === "") {
//       setInfoErrorMessages((prev:any) => ({
//         ...prev,
//         pickUpDate: "Pick up date is required",
//       }));
//     }

//     if (dispatchFirstData?.dropOffAddress !== "" && !useSmartLocker) {
//       setInfoErrorMessages((prev:any) => ({
//         ...prev,
//         dropOffAddress: "",
//       }));
//     } else if (dispatchFirstData?.dropOffAddress === "" && !useSmartLocker) {
//       setInfoErrorMessages((prev:any) => ({
//         ...prev,
//         dropOffAddress: "Drop off address is required",
//       }));
//     }

//     if (dispatchFirstData?.dropOffLocker !== "" && useSmartLocker) {
//       setInfoErrorMessages((prev:any) => ({
//         ...prev,
//         dropOffLocker: "",
//       }));
//     } else if (dispatchFirstData?.dropOffLocker === "" && useSmartLocker) {
//       setInfoErrorMessages((prev:any) => ({
//         ...prev,
//         dropOffLocker: "Select a drop off address is required",
//       }));
//     }
  };
  
  export const validateFinishStepOne = (
    dispatchFirstData: Record<string, any>,
    useSmartLocker: boolean,
    infoErrorMessages:any,
    isLockerAvailabilityError:boolean,
    lockerAvailabilityData:any,
    costError:string,
    isCostLoading:boolean,
    isLockerLoading:boolean,
    errorCost:string,
    orderStatus:boolean,
    startDate:any,
    setStep: React.Dispatch<React.SetStateAction<number>>,
    step:number
  ) => {
   
    if (
      infoErrorMessages.dropOffLocker === "" &&
      (dispatchFirstData.dropOffAddress !== "" ||
        dispatchFirstData.dropOffLocker !== "") &&
      (((!isLockerAvailabilityError || lockerAvailabilityData?.Available) &&
        infoErrorMessages.dropOffAddress === "" &&
        errorCost === "" &&
        useSmartLocker) ||
        !useSmartLocker) &&
      !isNaN(Number(dispatchFirstData.weight)) &&
      costError === "no" &&
      orderStatus !== false &&
      !isCostLoading &&
      !isLockerLoading &&
      errorCost === "" &&
      infoErrorMessages.weight === "" &&
      dispatchFirstData.pickUpDate !== "" &&
      dispatchFirstData.dropOffZone !== "" &&
      dispatchFirstData.deliveryType !== "" &&
      infoErrorMessages.pickUpDate === "" &&
      dispatchFirstData.weight !== "" &&
      // dispatchFirstData.weight &&
      infoErrorMessages.weight === "" &&
      infoErrorMessages.dropOffAddress === "" &&
      startDate !== null &&
      infoErrorMessages.pickUpZone === "" &&
      infoErrorMessages.dropOffZone === "" &&
      infoErrorMessages.pickUpAddress === "" &&
      dispatchFirstData.pickUpZone !== "" &&
      dispatchFirstData.pickUpDate !== "" &&
      dispatchFirstData.pickUpAddress !== "")
      {
        setStep(step + 1);
      } else {
        setStep(1);
      }
  };
 