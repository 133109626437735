export const handleDropOffSelection = (
    option: any,
    state: any,
    setDropOffState: React.Dispatch<React.SetStateAction<any>>,
    setQueryTwo: React.Dispatch<React.SetStateAction<string>>,
    setIsOpenTwo: React.Dispatch<React.SetStateAction<boolean>>,
    setDispatchFirstData: React.Dispatch<React.SetStateAction<any>>,
    setInfoErrorMessages: React.Dispatch<React.SetStateAction<any>>
  ) => {
    if (option.location === "") {
        setInfoErrorMessages((prev:any) => ({
          ...prev,
          dropOffZone: "Drop off zone is required",
        }));
      }
      if (option.location !== "") {
        setInfoErrorMessages((prev:any) => ({
          ...prev,
          dropOffZone: "",
        }));
      }
  
    setDropOffState(state);
    setQueryTwo(option.location);
    setIsOpenTwo(false);
    setDispatchFirstData((prev:any) => ({
      ...prev,
      dropOffLocationName: option.location,
      dropOffZone: option.zoneid,
      dropOffLocationId: option.id,
    }));
  };
  
  export const handleIntlDropOffSelection = (
    option: any,
    setQueryTwo: React.Dispatch<React.SetStateAction<string>>,
    setIsOpenTwo: React.Dispatch<React.SetStateAction<boolean>>,
    setDispatchFirstData: React.Dispatch<React.SetStateAction<any>>,
    setInfoErrorMessages: React.Dispatch<React.SetStateAction<any>>
  ) => {
    if (option === "") {
        setInfoErrorMessages((prev:any) => ({
          ...prev,
          dropOffZone: "Drop off zone is required",
        }));
      }
      if (option !== "") {
        setInfoErrorMessages((prev:any) => ({
          ...prev,
          dropOffZone: "",
        }));
      }
  
    setQueryTwo(option?.name);
    setIsOpenTwo(false);
    setDispatchFirstData((prev:any) => ({
      ...prev,
      dropOffLocationName: option?.name,
      dropOffZone: option.id,
      dropOffLocationId: option.id,
    }));
  };
  