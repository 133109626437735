export const isFormValid = (
    dispatchFirstData: any,
    infoErrorMessages: any
  ): boolean => {
    return (
        dispatchFirstData.senderName !== "" &&
        dispatchFirstData.senderPhone !== "" &&
        !isNaN(Number(dispatchFirstData.senderPhone)) &&
        dispatchFirstData.senderEmail !== "" &&
        infoErrorMessages.recipientEmail === "" &&
        dispatchFirstData.recipientName !== "" &&
        dispatchFirstData.recipientPhone !== "" &&
        !isNaN(Number(dispatchFirstData.recipientPhone)) &&
        dispatchFirstData.quantity !== "" &&
        !isNaN(Number(dispatchFirstData.quantity)) &&
        dispatchFirstData.itemDescription !== "" &&
        infoErrorMessages.cost === "" &&
        dispatchFirstData.cost !== "" &&
        !isNaN(Number(dispatchFirstData.cost))
    );
  };