import React from "react";
import { InformationLayout } from "../../InfoLayout";
import SelectInput from "../../form/SelectInput";
import { Button } from "../../Button";
import { Input } from "../../form/Input";
import { SearchableDropdown } from "../../form/Dropdown";
import { handleCopy } from "../../../helper/handleCopy";

interface Props{
    setShowTerms:React.Dispatch<React.SetStateAction<boolean>>;
    errorCost:string
    queryTwo:string
    handleCountryOption:(value:any)=>void
    locationData:any
    infoErrorMessages:any
    showAddresses:any
    showClipboard:boolean
    copiedAdd:string
    query:string
    dispatchFirstData:any
    itemCategory:any,
    isCostLoading:boolean
    onFinishStepOne:()=>void
    handleChange:(e:any)=>void
    focusHandleDropOff:()=>void
    handleItemOption:(value:any)=>void
    handleOptionClickTwo:(value:any)=>void
    isOpenThree:boolean
    queryThree:string
    setIsOpenThree:React.Dispatch<React.SetStateAction<boolean>>;
    setZoneOptionThree:React.Dispatch<React.SetStateAction<any>>;
    setQueryThree:React.Dispatch<React.SetStateAction<string>>;
    filteredDropOffOptions:any
    setCopiedAdd: React.Dispatch<React.SetStateAction<string>>,
    setShowClipboard: React.Dispatch<React.SetStateAction<boolean>>
}


const ImportStepOne =({
    setShowTerms,
    errorCost,
    queryTwo,
    handleCountryOption,
    locationData,
    infoErrorMessages,
    showAddresses,
    showClipboard,
    copiedAdd,
    dispatchFirstData,
    itemCategory,
    query,
    isCostLoading,
    onFinishStepOne,
    handleChange,
    isOpenThree,
    setIsOpenThree,
    setZoneOptionThree,
    queryThree,
    setQueryThree,
    focusHandleDropOff,
    handleOptionClickTwo,
    handleItemOption,
    filteredDropOffOptions,
    setCopiedAdd,
    setShowClipboard
}:Props)=>{

    return(
        <div className=" mb-32 ">
        <div className="flex md:flex-row flex-col md:space-y-0 space-y-20 bg-[#EFF6EE] px-5 min-h-[700px] pt-24 sm:px-10 xl:px-20 md:space-x-5  md:items-center md:justify-between  ">
          <div className="animate__animated animate__fadeInTopLeft">
            <h2 className="xl:text-[69px] font-ppmigra lg:text-[60px] text-[40px] md:text-[50px] whitespace-nowrap text-textColor font-bold">
              Import Now
            </h2>
            <p className="text-[18px] xl:block hidden font-[500]">
              Our smart solution can help meet your delivery <br /> needs in a
              quick and convenient manner.
            </p>
            <p className="text-[18px] font-onestMedium xl:hidden block ">
              Our smart solution can help meet your delivery needs in a quick
              and convenient manner.
            </p>
          </div>
          <div className="animate__animated animate__fadeInTopRight">
            <img src="/images/all-car.svg" alt="" />
          </div>
        </div>
        <div className="mt-5  w-full px-5 sm:px-10 xl:px-20 md:flex-row flex-col md:space-y-0 space-y-5   flex md:justify-between ">
          <div className=" w-full ">
            <h3 className="md:text-[32px] text-[24px] font-ppmigra text-textColor">
              Smart delivery solution
            </h3>
            <InformationLayout onClick={() => setShowTerms(true)} />
          </div>
          <div className="w-full ">
            <h4 className="text-textColor font-onestBold text-[20px]">
              Delivery details
            </h4>
            {errorCost !== "" && (
              <div className="bg-[#f19797] border-[red]  border max-w-[500px] px-5 py-2 rounded-md">
                <p className="text-white items-center flex text-[14px]  font-onestMedium">
                  {errorCost}
                </p>
              </div>
            )}
            <div className="mt-5 space-y-3 max-w-[500px]">
              <div>
                <SelectInput
                  value={queryTwo}
                  name={`random_${Math.random()}`}
                  placeholder={"Select country"}
                  handleChange={(name, value) => handleCountryOption(value)}
                  label="Select Country"
                  dropdownOptions={locationData.map((location: any) => ({
                    label: location?.country?.name,
                    value: location?.country?.name,
                  }))}
                />
                {infoErrorMessages.country && (
                  <p className="text-red-500 text-[12px]">
                    {infoErrorMessages.country}
                  </p>
                )}
              </div>
              <div className=" flex w-full items-center justify-between">
                <div className="flex w-full space-y-1 flex-col ">
                  <h6 className="text-[#000000] items-start flex flex-col text-[14px]  font-onestMedium">
                    {showAddresses?.length > 0 && "Address:"}
                    {showAddresses?.addresses?.map(
                      (add: any, idx: number) => (
                        <div className="w-full mt-1" key={idx}>
                          <label
                            htmlFor={"state"}
                            className="text-[#000000] mb-1  items-center flex text-[14px]  font-onestMedium"
                          >
                            Address
                          </label>
                          <div className={`flex space-x-3 mb-3  text-textColor p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border- border-inputBorderGreen  focus:border-2  focus:border-border-2 focus:border-inputBorderGreen rounded-[8px] bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full items-center`}>
                            <div
                              className="text-green-800  pl-1 flex flex-col text-[15px]"
                              key={idx}
                            >
                              {add?.address}
                            </div>
                            <div
                              onClick={() => handleCopy(add?.address,   setCopiedAdd,
                                setShowClipboard)}
                              className="cursor-pointer"
                            >
                              {showClipboard && copiedAdd === add?.address ? (
                                <div className="bg-black  whitespace-nowrap  h-fit w-fit px-3 py-1 rounded-[4px] text-white text-[8px]">
                                  <p> copied to clipboard</p>
                                </div>
                              ) : (
                                <img
                                  src="/images/copy.png"
                                  alt=""
                                  className="h-3 w-3"
                                />
                              )}
                            </div>
                          </div>
                          <label
                            htmlFor={"state"}
                            className="text-[#000000] mb-1  items-center flex text-[14px]  font-onestMedium"
                          >
                            State
                          </label>
                          {showAddresses?.state && (
                            <div className={`flex space-x-3 mb-3  text-textColor p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border-2 border-inputBorderGreen  focus:border-2  focus:border-border-2 focus:border-inputBorderGreen rounded-[8px] bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full items-center`}>
                              <div
                                className="text-green-800  pl-1 flex flex-col text-[15px]"
                                key={idx}
                              >
                                {showAddresses?.state}
                              </div>
                              <div
                                onClick={() =>
                                  handleCopy(showAddresses?.state,   setCopiedAdd,
                                    setShowClipboard)
                                }
                                className="cursor-pointer"
                              >
                                {showClipboard &&
                                copiedAdd === showAddresses?.state ? (
                                  <div className="bg-black  whitespace-nowrap  h-fit w-fit px-3 py-1 rounded-[4px] text-white text-[8px]">
                                    <p> copied to clipboard</p>
                                  </div>
                                ) : (
                                  <img
                                    src="/images/copy.png"
                                    alt=""
                                    className="h-3 w-3"
                                  />
                                )}
                              </div>
                            </div>
                          )}
                          <div className="flex w-full space-x-3 mb-1 justify-between">
                            <label
                              htmlFor={"state"}
                              className="text-[#000000]  w-full items-center flex text-[14px]  font-onestMedium"
                            >
                              City
                            </label>
                            <label
                              htmlFor={"state"}
                              className="text-[#000000] w-full items-start flex text-[14px]  font-onestMedium"
                            >
                              Post Code
                            </label>
                          </div>
                          <div className="flex  space-x-3 w-full">
                            {showAddresses?.city && (
                              <div className={`flex  mb-3 space-x-3 text-textColor p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border-2  border-inputBorderGreen  focus:border-2  focus:border-border-2 focus:border-inputBorderGreen rounded-[8px] bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full items-center`}>
                                <div
                                  className="text-green-800  pl-1 flex flex-col text-[15px]"
                                  key={idx}
                                >
                                  {showAddresses?.city}
                                </div>
                                <div
                                  onClick={() =>
                                    handleCopy(showAddresses?.city,   setCopiedAdd,
                                        setShowClipboard)
                                  }
                                  className="cursor-pointer"
                                >
                                  {showClipboard &&
                                  copiedAdd === showAddresses?.city ? (
                                    <div className="bg-black  whitespace-nowrap  h-fit w-fit px-3 py-1 rounded-[4px] text-white text-[8px]">
                                      <p> copied to clipboard</p>
                                    </div>
                                  ) : (
                                    <img
                                      src="/images/copy.png"
                                      alt=""
                                      className="h-3 w-3"
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                            {showAddresses?.postCode && (
                              <div className={`flex space-x-3 mb-3  text-textColor p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border-2 border-inputBorderGreen  focus:border-2  focus:border-border-2 focus:border-inputBorderGreen rounded-[8px] bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full items-center`}>
                                <div
                                  className="text-green-800  pl-1 flex flex-col text-[15px]"
                                  key={idx}
                                >
                                  {showAddresses?.postCode}
                                </div>
                                <div
                                  onClick={() =>
                                    handleCopy(showAddresses?.postCode,   setCopiedAdd,
                                        setShowClipboard)
                                  }
                                  className="cursor-pointer"
                                >
                                  {showClipboard &&
                                  copiedAdd === showAddresses?.postCode ? (
                                    <div className="bg-black  whitespace-nowrap  h-fit w-fit px-3 py-1 rounded-[4px] text-white text-[8px]">
                                      <p> copied to clipboard</p>
                                    </div>
                                  ) : (
                                    <img
                                      src="/images/copy.png"
                                      alt=""
                                      className="h-3 w-3"
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </h6>
                </div>
              </div>
              <p className="text-textGreen font-onestBold text-[16px] ">
                SHOPPER INFORMATION
              </p>
              <div>
                <p className="text-sm font-onestRegular text-gray-500">
                  Please enter the Tracking ID for accurate tracking
                </p>
                <Input
                  id="orderNumberInput"
                  type="text"
                  value={dispatchFirstData?.orderNumber}
                  onChange={handleChange}
                  label="Tracking ID"
                  name="orderNumber"
                  placeholder="Enter tracking id"
                  labelFor="orderNumber"
                  isRequired={true}
                />
                {infoErrorMessages.orderNumber && (
                  <p className="text-red-500 text-[12px]">
                    {infoErrorMessages.orderNumber}
                  </p>
                )}
              </div>
              <div>
                <Input
                  type="text"
                  value={dispatchFirstData?.vendorName}
                  onChange={handleChange}
                  label=" Vendor/Business Name"
                  name="vendorName"
                  placeholder="eg: Zara, ASOS, TESCO, next, MATALAN"
                  labelFor="vendorName"
                  isRequired
                />
                {infoErrorMessages.vendorName && (
                  <p className="text-red-500 text-[12px]">
                    {infoErrorMessages.vendorName}
                  </p>
                )}
              </div>
              <div>
                <SelectInput
                  value={query}
                  isRequired
                  name="item"
                  placeholder={"Select Item Category"}
                  handleChange={(name, value) => handleItemOption(value)}
                  label={"Select Item Category"}
                  dropdownOptions={itemCategory.map((item: any) => ({
                    label: item?.name,
                    value: item?.name,
                  }))}
                />
                {infoErrorMessages.item && (
                  <p className="text-red-500 text-[12px]">
                    {infoErrorMessages.item}
                  </p>
                )}
              </div>
              <div>
                <SearchableDropdown
                  lists={filteredDropOffOptions}
                  handleOptionClick={handleOptionClickTwo}
                  onFocus={focusHandleDropOff}
                  title="Select Drop-off Zone"
                  label={"Drop Zone"}
                  isOpen={isOpenThree}
                  name="dropOffZone"
                  setIsOpen={setIsOpenThree}
                  setQuery={setQueryThree}
                  query={queryThree}
                  isRequired
                  queryDeliverytype="local"
                  setZoneOption={setZoneOptionThree}
                  toggleDropdown={() => setIsOpenThree(!isOpenThree)}
                />
                {infoErrorMessages.dropOffZone && (
                  <p className="text-red-500 text-[12px]">
                    {infoErrorMessages.dropOffZone}
                  </p>
                )}
              </div>
              <div>
                <Input
                  type="text"
                  value={dispatchFirstData?.dropOffAddress}
                  onChange={handleChange}
                  label="Drop-off Address "
                  name="dropOffAddress"
                  placeholder="Drop off address"
                  labelFor="dropoffAddress"
                  isRequired
                />
                {infoErrorMessages.dropOffAddress && (
                  <p className="text-red-500 text-[12px]">
                    {infoErrorMessages.dropOffAddress}
                  </p>
                )}
              </div>
              <div>
                <Input
                  type="text"
                  value={dispatchFirstData?.itemName}
                  onChange={handleChange}
                  label=" Item Name"
                  name="itemName"
                  placeholder="Item name"
                  labelFor="itemName"
                  isRequired
                />
                {infoErrorMessages.itemName && (
                  <p className="text-red-500 text-[12px]">
                    {infoErrorMessages.itemName}
                  </p>
                )}
              </div>
              <div>
                <Input
                  type="text"
                  label="Weight"
                  placeholder=""
                  className="w-full"
                  weightShow
                  name="weight"
                  isRequired
                  value={dispatchFirstData.weight}
                  onChange={handleChange}
                />
                {infoErrorMessages.weight && (
                  <p className="text-red-500 text-[12px]">
                    {infoErrorMessages.weight}
                  </p>
                )}
              </div>
            </div>
            <div className="mt-10 max-w-[500px] ">
              <Button
                title="Next"
                className="!py-3"
                onClick={onFinishStepOne}
                disabled={isCostLoading}
                loading={false}
              />
            </div>
          </div>
        </div>
      </div>
    )
}

export default ImportStepOne