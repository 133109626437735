export const getReferralMessage = (status?: string) => {
    const messages: Record<
      string,
      { text: string; className: string }
    > = {
      "00": {
        text: "Congratulations! Your referral code is valid.",
        className: "text-green-600",
      },
      "01": {
        text: "An error occurred while processing your referral code.",
        className: "text-red-500",
      },
      "02": {
        text: "The referral code you used has expired. Please try a different one.",
        className: "text-red-500",
      },
      "03": {
        text: "Oops! The referral code you entered is not valid.",
        className: "text-red-500",
      },
      "04": {
        text: "The order limit for the referral code has been reached.",
        className: "text-red-500",
      },
      "05": {
        text: "Oops! This referral code is not currently active.",
        className: "text-red-500",
      },
    };
  
    const message = messages[status ?? ""];
  
 
    return message?.text ?? "";
  };