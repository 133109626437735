/* eslint-disable */
import { useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { PageLayout } from "../components/Layout";
import {
  useGetDropOffZonesQuery,
  useGetIntlZonesMutation,
  useGetPickUpStateQuery,
  useGetPickUpZonesQuery,
} from "../services/slices/getZonesSlice";
import { usePaystackPayment } from "react-paystack";
import {
  useGetCostPriceMutation,
  useGetIntlCostPriceMutation,
  useGetIntlReferralPriceMutation,
  useGetIntlWeightQuery,
} from "../services/slices/costSlice";
import {
  useOrderMultipleMutation,
  useRemoveOrderMutation,
  useUpdateReferenceMutation,
} from "../services/slices/multiple";
import {
  handleErrorToast,
} from "../helper";
import {
  DashboardModalAction,
  ExportInfoModal,
  ShowErrorandYS,
  ShowOrderErrorandD,
  ShowOrderLockYF,
  ShowTermsModal,
} from "../components/Modal";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { useGetReferalStatusMutation } from "../services/slices/referralSlice";
import { useGetTermsQuery } from "../services/slices/terms";
import { useSearchParams } from "react-router-dom";
import {
  useGetLockerAvailabilityMutation,
  useGetLockersMutation,
} from "../services/slices/lockers";
import { CostProps, Props, RefProps } from "../type";
import StepOne from "../components/dispatch/local/StepOne";
import StepTwo from "../components/dispatch/local/StepTwo";
import StepThree from "../components/dispatch/local/StepThree";
import {
  initialDispatchValues,
  initialValues,
} from "../constant/dispatchInitialValues";
import { createUserSchema, createUserSchemaTwo } from "../helper/schema";
import { clearErrorMessage } from "../helper/clearErrorMessage";
import { list } from "../constant/list";
import {
  validateDispatchData,
  validateFinishStepOne,
} from "../helper/validateStepOne";
import { handleDispatchOptionClick } from "../helper/handleOptionClick";
import { checkDateRange } from "../helper/checkDateRange";
import { scrollToTop } from "../helper/scrollToTop";
import { generateRandomDataWithDelay } from "../helper/randomNumber";
import { checkOrderStatus } from "../helper/checkOrderStatus";
import {
  checkShowOrderError,
  checkShowOrderErrorOne,
  checkShowOrderErrorThree,
} from "../helper/checkShowOrderError";
import { isFormValid } from "../helper/validateStepTwo";
import { validateLockerLimits } from "../helper/validateLockerLimits";
import { updateDispatchData } from "../helper/updateDispatchData";
import { handleWeightSelection } from "../helper/handleWeightSelection";
import { validateAndSetEmailError } from "../helper/validateAndSetEmailError";
import { validateRequiredField } from "../helper/validateRequiredField";
import { generateRequestPayload } from "../helper/generateRequestPayload";
import { handleFocusValidation } from "../helper/handleFocusValidation";
import { updateDropOffDetails } from "../helper/updateDropOffDetails";
import {
  handleDropOffSelection,
  handleIntlDropOffSelection,
} from "../helper/optionClicks";

const DispatchNow = () => {
  const [step, setStep] = useState(1);
  const [pickUpZoneData, setPickupZoneData] = useState([]);
  const [dropOffZoneData, setDropOffZoneData] = useState([]);
  const [costDataResult, setCostDataResult] = useState<any>([]);
  const [zoneOption, setZoneOption] = useState<any>(null);
  const [zoneOptionTwo, setZoneOptionTwo] = useState<any>(null);
  const [query, setQuery] = useState("");
  const [clientUserId, setClientUserId] = useState("");
  const [clientUserPhone, setClientUserPhone] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [applyReferral, setApplyReferral] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState<boolean>(false);
  const [applyReferralTwo, setApplyReferralTwo] = useState(false);
  const [queryTwo, setQueryTwo] = useState("");
  const [queryDeliverytype, setQueryDeliveryType] = useState("");
  const [costError, setCostError] = useState("no");
  const [referenceId, setReferenceId] = useState("");
  const [referenceIdTwo, setReferenceIdTwo] = useState("");
  const [weight, setWeight] = useState({
    id: null,
    name: "",
  });
  const [startDate, setStartDate] = useState(null);
  const [isOpenTwo, setIsOpenTwo] = useState(false);
  const [isOpenWeight, setIsOpenWeight] = useState(false);
  const [showOrderError, setShowOrderError] = useState(false);
  const [showOrderErrorOne, setShowOrderErrorOne] = useState(false);
  const [regenerate, setRegenerate] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showOrderErrorThree, setShowOrderErrorThree] = useState(false);
  const [errorCost, setErrorCost] = useState("");
  const [payAmount, setPayAmount] = useState("");
  const [dropOffState, setDropOffState] = useState("");
  const [pickUpState, setPickUpState] = useState("");
  const [infoErrorMessages, setInfoErrorMessages] = useState(initialValues);
  const [isOpenDeliveryType, setIsOpenDeliveryType] = useState(false);
  const [useSmartLocker, setUseSmartLocker] = useState(false);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);
  const [orderStatus, setOrderStatus] = useState(true);
  const { data: pickUpData } = useGetPickUpZonesQuery();
  const { data: dropOffData } = useGetDropOffZonesQuery();
  const { data: termsAndCondition } = useGetTermsQuery();
  const { data: pickUpStatesData } = useGetPickUpStateQuery();
  const [
    getCostPrice,
    { data: costData, isLoading: isCostLoading, isError: isCostError },
  ] = useGetCostPriceMutation();
  const [getLockers, { data: lockerData, isLoading: isLockerLoading }] =
    useGetLockersMutation();
  const [
    getLockersAvailability,
    {
      data: lockerAvailabilityData,
      isLoading: isLockerAvailabilityLoading,
      isError: isLockerAvailabilityError,
    },
  ] = useGetLockerAvailabilityMutation();
  const [
    orderMultiple,
    {
      data: multipleData,
      isLoading: isMultipleLoading,
      data: orderData,
      error: orderError,
      isError: isOrderError,
    },
  ] = useOrderMultipleMutation();
  const [getIntlCost, { data: intlCostData, isLoading: isLoadingIntlCost }] =
    useGetIntlCostPriceMutation();
  const [
    deleteOrder,
    { data: deleteData, error: deleteDataError, isLoading: deleteLoading },
  ] = useRemoveOrderMutation();
  const [updateReference] = useUpdateReferenceMutation();
  const [
    getIntlReferralCost,
    { data: intlReferralData, isLoading: isLoadingReferralCost },
  ] = useGetIntlReferralPriceMutation();
  const { data: intlWeightData } = useGetIntlWeightQuery();
  const [getIntlZones, { data: intlZonesData }] = useGetIntlZonesMutation();
  const [
    getReferralCode,
    { data: referralData, isLoading: isReferralLoading },
  ] = useGetReferalStatusMutation();
  const [params] = useSearchParams();
  const deliveryType = params?.get("type");
  const [dispatchFirstData, setDispatchFirstData] = useState(
    initialDispatchValues
  );
  const userDetails: any = localStorage.getItem("fez-user");

  const checkValidate = async () => {
    try {
      await createUserSchema(setInfoErrorMessages, query).validate(
        dispatchFirstData
      );
    } catch (err: any) {
      console.error({
        error: err,
      });
    }
  };

  useEffect(() => {
    validateRequiredField(
      dispatchFirstData?.dropOffLockerAddress,
      "dropOffLocker",
      "Select a drop off locker",
      useSmartLocker,
      setInfoErrorMessages
    );
  }, [useSmartLocker, dispatchFirstData?.dropOffLockerAddress]);

  useEffect(() => {
    validateRequiredField(
      dispatchFirstData?.recipientEmail,
      "recipientEmail",
      "Recipient email is required",
      useSmartLocker,
      setInfoErrorMessages
    );
  }, [useSmartLocker, dispatchFirstData?.recipientEmail]);

  const checkValidateTwo = async () => {
    try {
      await createUserSchemaTwo(setInfoErrorMessages, useSmartLocker).validate(
        dispatchFirstData
      );
    } catch (err: any) {
      console.error({
        error: err,
      });
    }
  };

  useEffect(() => {
    validateAndSetEmailError(
      dispatchFirstData?.senderEmail,
      "senderEmail",
      setInfoErrorMessages
    );
  }, [dispatchFirstData?.senderEmail]);

  useEffect(() => {
    validateAndSetEmailError(
      dispatchFirstData?.recipientEmail,
      "recipientEmail",
      setInfoErrorMessages
    );
  }, [dispatchFirstData?.recipientEmail]);

  useEffect(() => {
    if (userDetails) {
      const { clientID, clientsPhone } = JSON.parse(userDetails);

      setClientUserId(clientID);
      setClientUserPhone(clientsPhone);
    }
  }, [userDetails]);

  const getCostPriceData = useCallback(async () => {
    setErrorCost("");
    setCostError("no");
    setOrderStatus(true);
    try {
      const requestPayload: CostProps = {
        ori_zone: dispatchFirstData.pickUpZone,
        des_zone: dispatchFirstData.dropOffZone,
        deliveryType: dispatchFirstData.deliveryType,
        weight: Number(dispatchFirstData.weight),
        dropOffId: dispatchFirstData.dropOffId,
      };
      if (lockerAvailabilityData?.Available && useSmartLocker) {
        requestPayload.locker = true;
      }
      await getCostPrice(requestPayload).unwrap();
    } catch (error) {
      setCostError("Network Error! Please, try again!");
    }
  }, [
    dispatchFirstData.pickUpZone,
    dispatchFirstData.dropOffZone,
    dispatchFirstData.deliveryType,
    dispatchFirstData.weight,
    lockerAvailabilityData?.Available,
    useSmartLocker,
    getCostPrice, // Ensure this is stable
  ]);

  useEffect(() => {
    if (queryDeliverytype.toLowerCase() !== "local" && weight?.id) {
      getIntlZones(weight?.id);
    }
  }, [queryDeliverytype, weight]);


  const onSuccess = () => {
    updateReferalCode();
    setShowPaymentSuccessModal(true);
    setDispatchFirstData(initialDispatchValues);
    setQuery("");
    setQueryTwo("");
    setStartDate(null);
  };
  const onClose = () => {
    onGenerate();
    setRegenerate(true);
    console.log("closed");
  };

  const getMultipleData = useCallback(async () => {
    const requestPayload: Props = generateRequestPayload(
      queryDeliverytype,
      dispatchFirstData,
      costData,
      intlCostData,
      referralData,
      intlReferralData,
      referenceId,
      zoneOption,
      zoneOptionTwo,
      clientUserId,
      clientUserPhone
    );
    if (referralData?.status === "00" || intlReferralData?.status === "00") {
      requestPayload.referralCode = dispatchFirstData?.referralCode;
    }
    if (
      lockerAvailabilityData?.Available &&
      dispatchFirstData?.dropOffLocker !== "" &&
      useSmartLocker
    ) {
      requestPayload.lockerID = dispatchFirstData?.dropOffLocker;
    }
    await orderMultiple(requestPayload)
      .unwrap()
      .then((data) => {
        localStorage.setItem("orderNo", data?.orderNos[0]);
        initializePayment(onSuccess, onClose);
      })
      .catch((error: any) => {
        console.log(error);
        if (error?.data?.description) {
          toast.error(error?.data?.description, {
            className: "toast-error",
            progressClassName: "Toastify__progress-bar--error",
          });
        } else {
          toast.error("Network Error", {
            className: "toast-error",
            progressClassName: "Toastify__progress-bar--error",
          });
        }
        setCostError("Network Error! Please, try again!");
      });
  }, [
    clientUserId,
    clientUserPhone,
    costData,
    dispatchFirstData,
    intlCostData,
    intlReferralData,
    lockerAvailabilityData?.Available,
    orderMultiple,
    queryDeliverytype,
    referenceId,
    referralData,
    useSmartLocker,
    zoneOption,
    zoneOptionTwo,
  ]);

  useEffect(() => {
    if (orderError && isOrderError) {
      console.log("orderError", orderError)

      handleErrorToast(orderError);
      setCostError("Network Error! Please, try again!");
    }
  }, [orderError, isOrderError]);
  useEffect(() => {
    if (pickUpData) {
      setPickupZoneData(pickUpData.zones);
    }
  }, [pickUpData]);

  useEffect(() => {
    if (dropOffData && queryDeliverytype.toLowerCase() === "local") {
      setDropOffZoneData(dropOffData.zones);
    }
    if (dropOffData && queryDeliverytype.toLowerCase() !== "local") {
      setDropOffZoneData([]);
      setDispatchFirstData((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    }
  }, [dropOffData, queryDeliverytype]);

  useEffect(() => {
    if (isCostError) {
      setErrorCost("Network Error! Please, try again!");
    }
  }, [isCostError]);

  useEffect(() => {
    if (queryDeliverytype.toLowerCase() === "local") {
      if (referralData && referralData?.discountedRate !== "") {
        setPayAmount(referralData?.discountedRate);
      } else {
        setPayAmount(costData?.discountedRate);
      }
    } else {
      if (intlReferralData && intlReferralData?.discountedRate !== "") {
        setPayAmount(intlReferralData?.discountedRate);
      } else {
        setPayAmount(intlCostData?.discountedRate);
      }
    }
  }, [
    referralData,
    intlCostData?.discountedRate,
    costData?.discountedRate,
    intlReferralData,
    queryDeliverytype,
  ]);

  const config = {
    reference: referenceIdTwo,
    email: dispatchFirstData?.senderEmail,
    amount: Math.round(Number(payAmount) * 100),
    publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
  };

  const initializePayment = usePaystackPayment(config);

  const filteredOptions = pickUpZoneData.reduce((acc: any, zone: any) => {
    const filteredLocations = zone.location.filter((location: any) =>
      location?.location?.toLowerCase().includes(query?.toLowerCase())
    );

    if (filteredLocations.length > 0) {
      acc.push({
        zone: zone.name,
        locations: filteredLocations,
        state: zone.state_id === null ? zone.hub?.state_id : zone.state_id,
      });
    }
    return acc;
  }, []);
  const filteredOptionsTwo: any = pickUpStatesData?.data?.filter((item: any) =>
    item?.state?.toLowerCase()?.includes(query.toLowerCase())
  );

  const filteredDropOffOptions = dropOffZoneData.reduce(
    (acc: any, zone: any) => {
      const filteredLocations = zone.location.filter((location: any) =>
        location.location.toLowerCase().includes(queryTwo?.toLowerCase())
      );

      if (filteredLocations.length > 0) {
        acc.push({
          zone: zone.name,
          locations: filteredLocations,
          state: zone.state_id === null ? zone.hub?.state_id : zone.state_id,
        });
      }
      return acc;
    },
    []
  );

  const filteredIntLocations = Array.isArray(intlZonesData?.zones)
    ? queryTwo !== ""
      ? intlZonesData.zones.filter((location: any) =>
          location?.name.toLowerCase().includes(queryTwo?.toLowerCase())
        )
      : intlZonesData.zones
    : [];

  const handleOptionClick = (option: any, state: any) => {
    handleDispatchOptionClick(
      option,
      state,
      queryDeliverytype,
      setIsOpen,
      setPickUpState,
      setInfoErrorMessages,
      setDispatchFirstData,
      setQuery
    );
  };

  const handleOptionClickTwo = (option: any, state: any) => {
    handleDropOffSelection(
      option,
      state,
      setDropOffState,
      setQueryTwo,
      setIsOpenTwo,
      setDispatchFirstData,
      setInfoErrorMessages
    );
  };

  const handleIntlOption = (option: any) => {
    handleIntlDropOffSelection(
      option,
      setQueryTwo,
      setIsOpenTwo,
      setDispatchFirstData,
      setInfoErrorMessages
    );
  };

  useEffect(() => {
    const getSmartLockerLocation = async () => {
      if (
        dispatchFirstData?.dropOffZone &&
        queryDeliverytype?.toLowerCase() === "local"
      ) {
        const body = {
          zone: dispatchFirstData?.dropOffZone,
        };
        await getLockers(body).unwrap();
      }
    };
    getSmartLockerLocation();
  }, [
    dispatchFirstData?.dropOffZone,
    getLockers,
    queryDeliverytype,
    useSmartLocker,
  ]);

  const focusHandlePickUp = () => {
    handleFocusValidation(query, "pickUpZone", setInfoErrorMessages);
  };

  const focusHandleDropOff = () => {
    handleFocusValidation(queryTwo, "dropOffZone", setInfoErrorMessages);
  };

  const handleWeightType = (e: any) => {
    handleWeightSelection(
      e,
      setWeight,
      setDispatchFirstData,
      setInfoErrorMessages,
      setIsOpenWeight
    );
  };

  const handleChange = async (evt: any) => {
    const { name, value } = evt.target;

    await updateDispatchData(
      name,
      value,
      setDispatchFirstData,
      setInfoErrorMessages
    );

    try {
      await createUserSchema(setInfoErrorMessages, query).validateAt(name, {
        [name]: value,
      });
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleChangeTwo = async (evt: any) => {
    const { name, value, checked } = evt.target;
    setDispatchFirstData((prev) => ({
      ...prev,
      [name]: name === "fragile" ? checked : value,
    }));
    clearErrorMessage(name, value, setInfoErrorMessages);
    try {
      await createUserSchemaTwo(
        setInfoErrorMessages,
        useSmartLocker
      ).validateAt(name, { [name]: value });
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (queryDeliverytype.toLowerCase() !== "local" && startDate !== "") {
      const isInRange = checkDateRange(startDate);
      if (isInRange) {
        setShowPriceModal(true);
      } else {
        setShowPriceModal(false);
      }
    }
  }, [startDate, queryDeliverytype]);

  const onChangeDate = async (selectedDate: any) => {
    setStartDate(selectedDate);

    if (selectedDate !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, pickUpDate: "" }));
    }
    if (selectedDate !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, pickUpDate: "" }));
    }
    setDispatchFirstData((prev) => ({
      ...prev,
      pickUpDate: moment(selectedDate).format("YYYY-MM-DD HH:mm:ss"),
    }));

    try {
      await createUserSchema(setInfoErrorMessages, query).validateAt(
        "pickUpDate",
        { pickUpDate: selectedDate }
      );
    } catch (error: any) {
      setInfoErrorMessages(error.message);
    }
  };

  const onFocusDate = () => {
    if (startDate === null) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        pickUpDate: "Pick up date is required!",
      }));
    }
    if (startDate !== null) {
      setInfoErrorMessages((prev) => ({ ...prev, pickUpDate: "" }));
    }
  };

  useEffect(() => {
    if (step === 2 || step === 3) {
      scrollToTop();
    }
  }, [step]);

  const onGenerate = () => {
    const response = generateRandomDataWithDelay();

    if (clientUserId) {
      setReferenceId(`c_${response}_${clientUserId}_OP`);
      setReferenceIdTwo(`${response}_${clientUserId}_OP`);
    }
  };
  const onFinishStepOne = async () => {
    await validateDispatchData(
      dispatchFirstData,
      useSmartLocker,
      setInfoErrorMessages
    );

    try {
      onGenerate();
      await checkValidate();
      await validateFinishStepOne(
        dispatchFirstData,
        useSmartLocker,
        infoErrorMessages,
        isLockerAvailabilityError,
        lockerAvailabilityData,
        costError,
        isCostLoading,
        isLockerLoading,
        errorCost,
        orderStatus,
        startDate,
        setStep,
        step
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (costData) {
      setCostDataResult(costData);
    }
    if (intlCostData) {
      setCostDataResult(intlCostData);
    }
  }, [costData, intlCostData]);

  useEffect(() => {
    const cleanup = checkOrderStatus(
      costData,
      costDataResult,
      intlCostData,
      setOrderStatus,
      setErrorCost
    );

    return cleanup;
  }, [costData, costDataResult, intlCostData]);

  useEffect(() => {
    if (orderStatus === false) {
      setCostError("Network Error! Please, try again!");
    }
  }, [orderStatus]);

  useEffect(() => {
    checkShowOrderError(costData, intlCostData, setShowOrderError);
  }, [costData, intlCostData]);

  const getIntlCostData = useCallback(async () => {
    setErrorCost("");
    setCostError("no");
    setOrderStatus(true);
    try {
      const requestPayload = {
        zone: dispatchFirstData.dropOffLocationId,
        weight: Number(weight.id),
        dropOffId: dispatchFirstData.dropOffId,
      };

      await getIntlCost(requestPayload).unwrap();
    } catch (error) {
      setCostError("Network Error! Please, try again!");
    }
  }, [
    dispatchFirstData.dropOffLocationId,
    weight.id,
    dispatchFirstData.dropOffId,
    getIntlCost,
  ]);

  const deleteDataOrder = async () => {
    try {
      const requestPayload = {
        orderNo: localStorage.getItem("orderNo"),
      };
      await deleteOrder(requestPayload).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (deleteData || deleteDataError) {
      getMultipleData();
    }
  }, [deleteData, deleteDataError, getMultipleData]);

  const getIntlReferralCostData = useCallback(async () => {
    setErrorCost("");
    setCostError("no");
    setOrderStatus(true);
    try {
      const requestPayload = {
        zone: dispatchFirstData.dropOffLocationId,
        weight: Number(weight.id),
        referralCode: dispatchFirstData.referralCode,
      };
      await getIntlReferralCost(requestPayload).unwrap();
    } catch (error) {
      setCostError("Network Error! Please, try again!");
    }
  }, [
    dispatchFirstData.dropOffLocationId,
    dispatchFirstData.referralCode,
    weight.id,
    getIntlReferralCost,
  ]);

  useEffect(() => {
    if (applyReferralTwo) {
      getIntlReferralCostData();
    }
  }, [
    dispatchFirstData.dropOffLocationId,
    dispatchFirstData.weight,
    applyReferralTwo,
    getIntlReferralCostData,
  ]);

  useEffect(() => {
    if (
      dispatchFirstData.dropOffZone !== "" &&
      dispatchFirstData.weight !== "" &&
      queryDeliverytype.toLowerCase() !== "local"
    ) {
      getIntlCostData();
    }
  }, [
    dispatchFirstData.pickUpZone,
    dispatchFirstData.dropOffZone,
    dispatchFirstData.weight,
    queryDeliverytype,
    getIntlCostData,
  ]);

  useEffect(() => {
    checkShowOrderErrorThree(costDataResult, setShowOrderErrorThree);
  }, [costDataResult]);

  useEffect(() => {
    checkShowOrderErrorOne(costData, intlCostData, setShowOrderErrorOne);
  }, [costData, intlCostData]);

  useEffect(() => {
    if (
      dispatchFirstData.pickUpZone !== "" &&
      dispatchFirstData.dropOffZone !== "" &&
      dispatchFirstData.weight !== "" &&
      queryDeliverytype.toLowerCase() === "local"
    ) {
      getCostPriceData();
    }
  }, [
    dispatchFirstData.pickUpZone,
    dispatchFirstData.dropOffZone,
    queryDeliverytype,
    dispatchFirstData.weight,
    lockerAvailabilityData?.Available,
    useSmartLocker,
    getCostPriceData,
  ]);

  const getReferralData = useCallback(async () => {
    setErrorCost("");
    try {
      const requestPayload = {
        ori_zone: dispatchFirstData.pickUpZone,
        des_zone: dispatchFirstData.dropOffZone,
        deliveryType: dispatchFirstData.deliveryType,
        referralCode: dispatchFirstData.referralCode,
        weight: Number(dispatchFirstData.weight),
      };
      await getReferralCode(requestPayload).unwrap();
    } catch (error) {
      setErrorCost("Network Error! Please, try again!");
    }
  }, [
    dispatchFirstData.pickUpZone,
    dispatchFirstData.dropOffZone,
    dispatchFirstData.deliveryType,
    dispatchFirstData.referralCode,
    dispatchFirstData.weight,
    getReferralCode, 
  ]);

  const onApplyReferral = useCallback(() => {
    if (
      dispatchFirstData.pickUpZone !== "" &&
      dispatchFirstData.dropOffZone !== "" &&
      dispatchFirstData.referralCode !== "" &&
      dispatchFirstData.deliveryType !== ""
    ) {
      getReferralData();
    }
  }, [
    dispatchFirstData.pickUpZone,
    dispatchFirstData.dropOffZone,
    dispatchFirstData.referralCode,
    dispatchFirstData.deliveryType,
    getReferralData,
  ]);

  useEffect(() => {
    if (applyReferral) {
      onApplyReferral();
    }
  }, [
    dispatchFirstData.pickUpZone,
    dispatchFirstData.dropOffZone,
    dispatchFirstData.deliveryType,
    dispatchFirstData.weight,
    applyReferral,
    onApplyReferral,
  ]);

  const onFinishStepTwo = async () => {
    try {
      await checkValidateTwo();
      if (isFormValid(dispatchFirstData, infoErrorMessages)) {
        setStep(step + 1);
      } else {
        console.log("err");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const toggleDropdown = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (isOpen) {
      setIsOpenDeliveryType(false);
      setIsOpenTwo(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpenDeliveryType) {
      setIsOpen(false);
      setIsOpenTwo(false);
    }
  }, [isOpenDeliveryType]);

  useEffect(() => {
    if (isOpenTwo) {
      setIsOpen(false);
      setIsOpenDeliveryType(false);
    }
  }, [isOpenTwo]);

  const updateReferalCode = async () => {
    const orderNos = localStorage.getItem("orderNo");
    try {
      const body: RefProps = {
        orderNo: orderNos ? orderNos : "",
      };
      if (
        referralData?.status === "00" &&
        referralData?.discountedRate !== ""
      ) {
        body.referralCode = dispatchFirstData.referralCode;
        await updateReference(body).unwrap();
      }
      if (
        intlReferralData?.status === "00" &&
        intlReferralData?.discountedRate !== ""
      ) {
        body.referralCode = dispatchFirstData.referralCode;
        await updateReference(body).unwrap();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleReload = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (deliveryType?.toLowerCase() === "local") {
      setQueryDeliveryType("Local");
    } else {
      setQueryDeliveryType("International");
    }
  }, [deliveryType]);

  const formattedTextElement = document.getElementById("formattedText");
  if (formattedTextElement) {
    if (termsAndCondition && termsAndCondition?.terms) {
      formattedTextElement.textContent = termsAndCondition?.terms;
    } else {
      formattedTextElement.textContent = "Terms and conditions not available.";
    }
  }

  const handleSelectLocker = (value: any, label: any) => {
    updateDropOffDetails(
      value,
      label,
      setDispatchFirstData,
      setInfoErrorMessages
    );
  };

  useEffect(() => {
    validateLockerLimits(lockerData, dispatchFirstData, setInfoErrorMessages);
  }, [
    dispatchFirstData?.dropOffLocker,
    dispatchFirstData?.weight,
    lockerData,
    dispatchFirstData?.dropOffLocker,
    dispatchFirstData?.weight,
    dispatchFirstData?.cost,
    dispatchFirstData,
  ]);

  useEffect(() => {
    if (dispatchFirstData?.dropOffLocker !== "" && useSmartLocker) {
      const getLockerAvailability = async () => {
        try {
          const body = {
            lockerID: dispatchFirstData?.dropOffLocker,
          };
          await getLockersAvailability(body).unwrap();
        } catch (error) {
          console.log(error);
        }
      };
      getLockerAvailability();
    }
  }, [
    dispatchFirstData?.dropOffLocker,
    useSmartLocker,
    getLockersAvailability,
  ]);

  return (
    <PageLayout>
      <div>
        <ToastContainer position="top-right" />
      </div>
      {step === 1 && (
        <StepOne
          toggleDropdown={toggleDropdown}
          handleOptionClick={handleOptionClick}
          focusHandlePickUp={focusHandlePickUp}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          setQuery={setQuery}
          setZoneOption={setZoneOption}
          setShowTerms={setShowTerms}
          onFocusDate={onFocusDate}
          errorCost={errorCost}
          infoErrorMessages={infoErrorMessages}
          list={list}
          startDate={startDate}
          query={query}
          setQueryDeliveryType={setQueryDeliveryType}
          queryDeliverytype={queryDeliverytype}
          isOpenDeliveryType={isOpenDeliveryType}
          onChangeDate={onChangeDate}
          intlWeightData={intlWeightData}
          setIsOpenWeight={setIsOpenWeight}
          isOpenWeight={isOpenWeight}
          handleWeightType={handleWeightType}
          setIsOpenDeliveryType={setIsOpenDeliveryType}
          weight={weight}
          filteredOptions={filteredOptions}
          filteredOptionsTwo={filteredOptionsTwo}
          dispatchFirstData={dispatchFirstData}
          handleChange={handleChange}
          filteredDropOffOptions={filteredDropOffOptions}
          handleOptionClickTwo={handleOptionClickTwo}
          focusHandleDropOff={focusHandleDropOff}
          isOpenTwo={isOpenTwo}
          setIsOpenTwo={setIsOpenTwo}
          setQueryTwo={setQueryTwo}
          queryTwo={queryTwo}
          setZoneOptionTwo={setZoneOptionTwo}
          dropOffState={dropOffState}
          pickUpState={pickUpState}
          isLockerLoading={isLockerLoading}
          useSmartLocker={useSmartLocker}
          lockerData={lockerData}
          setUseSmartLocker={setUseSmartLocker}
          setDispatchFirstData={setDispatchFirstData}
          lockerAvailabilityData={lockerAvailabilityData}
          isLockerAvailabilityLoading={isLockerAvailabilityLoading}
          onFinishStepOne={onFinishStepOne}
          isCostLoading={isCostLoading}
          isLoadingIntlCost={isLoadingIntlCost}
          handleSelectLocker={handleSelectLocker}
          filteredIntLocations={filteredIntLocations}
          handleIntlOption={handleIntlOption}
        />
      )}
      {step === 2 && (
        <StepTwo
          setStep={setStep}
          setShowTerms={setShowTerms}
          errorCost={errorCost}
          infoErrorMessages={infoErrorMessages}
          dispatchFirstData={dispatchFirstData}
          handleChangeTwo={handleChangeTwo}
          isMultipleLoading={isMultipleLoading}
          useSmartLocker={useSmartLocker}
          onFinishStepTwo={onFinishStepTwo}
          step={step}
        />
      )}
      {step === 3 && (
        <StepThree
          setStep={setStep}
          setShowTerms={setShowTerms}
          queryDeliverytype={queryDeliverytype}
          handleChange={handleChange}
          dispatchFirstData={dispatchFirstData}
          intlCostData={intlCostData}
          isMultipleLoading={isMultipleLoading}
          referralData={referralData}
          intlReferralData={intlReferralData}
          isReferralLoading={isReferralLoading}
          costData={costData}
          step={step}
          setApplyReferral={setApplyReferral}
          onApplyReferral={onApplyReferral}
          setApplyReferralTwo={setApplyReferralTwo}
          isLoadingReferralCost={isLoadingReferralCost}
          getIntlReferralCostData={getIntlReferralCostData}
          deleteLoading={deleteLoading}
          regenerate={regenerate}
          deleteDataOrder={deleteDataOrder}
          getMultipleData={getMultipleData}
        />
      )}
      {showPaymentSuccessModal && (
        <DashboardModalAction
          contentTitle={"Track Order Number"}
          content={`This is your tracking number, please copy it `}
          btnContent="Close"
          trackNo={
            multipleData?.orderNos[0]
              ? multipleData?.orderNos[0]
              : localStorage.getItem("orderNo")
          }
          onClick={() => {
            setShowPaymentSuccessModal(false);
            handleReload();
            localStorage.removeItem("orderNo");
          }}
        />
      )}
      {showOrderError && (
        <ShowOrderLockYF onClick={() => setShowOrderError(false)} />
      )}
      {showOrderErrorOne && (
        <ShowOrderErrorandD onClick={() => setShowOrderErrorOne(false)} />
      )}
      {showOrderErrorThree && (
        <ShowErrorandYS onClick={() => setShowOrderErrorThree(false)} />
      )}
      {showTerms && (
        <ShowTermsModal
          onClick={() => setShowTerms(false)}
          terms={termsAndCondition?.terms}
          id="formattedText"
        />
      )}
      {showPriceModal && (
        <ExportInfoModal onClick={() => setShowPriceModal(false)} />
      )}
    </PageLayout>
  );
};

export default DispatchNow;
