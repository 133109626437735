import Toggle from "react-toggle";
import "react-toggle/style.css";

export default function ToggleButton({
  data,
  handleFn,
}: {
  data: any;
  handleFn: (e: React.FormEvent) => void;
}): JSX.Element {
  return (
    <article className="mt-6">
      <label className="text-[#000000] w-fit text-[14px] font-onestMedium font-bold flex items-center gap-3">
        <span>Fragile</span>
        <Toggle name="fragile" checked={data} onChange={handleFn} />
      </label>
    </article>
  );
}
