interface Props {
  title?: string;
  className?: string;
  placeholder?: string;
  label?: any;
  active?: boolean;
  lists?: any;
  intlLists?: any;
  countryLists?: any;
  stateLists?:any
  itemLists?: any;
  query?: any;
  setQuery?: any;
  isOpen?: any;
  errorList?: any;
  name?: string;
  setIsOpen?: any;
  weight?: any;
  setZoneOption?: any;
  handleOptionClick?: any;
  handleOnChange?: any;
  onFocus?: any;
  toggleDropdown?: any;
  showCursor?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  zindex?: string;
  queryDeliverytype?: string;
}

export const DropDownSelect = ({
  lists,
  isRequired,
  query,
  title,
  name,
  handleOptionClick,
  toggleDropdown,
  setQuery,
  isOpen,
  disabled,
  placeholder,
  zindex,
}: Props) => {
  return (
    <div className="relative w-full">
      <p className="text-[#000000] text-[14px] mb-2 font-onestMedium">
        {title}
        {isRequired && <span className="text-red-500 text-[20px]">*</span>}
      </p>
      <div
        className="flex border-2 cursor-pointer border-inputBorderGreen  focus:border-2 rounded-[8px] focus:border-inputBorderGreen space-x-1 items-center"
        onClick={toggleDropdown}
      >
        <input
          type="text"
          value={query}
          name={name}
          readOnly
          autoComplete="off"
          onChange={(e) => setQuery(e.target.value)}
          disabled={disabled}
          className={`text-[#2c2c2c] font-bold cursor-pointer p-2 text-[14px]  outline-0 focus:outline-none focus:ring-0   focus:border-0 border-0   bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full`}
          placeholder={placeholder}
        />
        <img
          src={"/images/arrow-down-s-line.svg"}
          alt="drop-down"
          className="mt-0.5 pr-2"
        />
      </div>
      {isOpen && (
        <ul
          className={` absolute cursor-pointer  ${
            lists?.length > 0 ? "max-h-[300px]" : "h-[100px]"
          }  bg-white shadow-lg  z-[100]  px-3 py-2   w-full overflow-y-scroll border-0 rounded-t-none  mt-1`}
        >
          {lists?.map((d: any) => (
            <li
              key={d?.id}
              onClick={() => handleOptionClick(d)}
              className="hover:bg-gray-200"
            >
              <p className="text-[black] p-2">{d?.name}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const SearchableDropdown = ({
  lists,
  query,
  title,
  handleOptionClick,
  toggleDropdown,
  setQuery,
  isOpen,
  setIsOpen,
  name,
  showCursor,
  setZoneOption,
  isRequired,
  onFocus,
  intlLists,
  countryLists,
  stateLists,
  itemLists,
  weight,
  disabled,
  errorList,
  queryDeliverytype
}: Props) => {
  return (
    <div className="relative w-full">
      <p className="text-[#000000] items-center flex text-[14px]  font-onestMedium">
        {title}
        {isRequired && <span className="text-red-500 text-[20px]">*</span>}
      </p>
      <div
        className={`flex border-2 ${
          showCursor && "cursor-pointer"
        } border-inputBorderGreen  focus:border-2 rounded-[8px] focus:border-inputBorderGreen space-x-1 items-center`}
        onClick={toggleDropdown}
      >
        <input
          type="text"
          name={name}
          value={query}
          // onTouchStart={toggleDropdown}
          onChange={(e: any) => {
            setQuery(e.target.value);
            setIsOpen(true);
          }}
          autoComplete="off"
          disabled={disabled}
          onFocus={onFocus}
          // onFocus={toggleDropdown}
          className={`${
            disabled && " font-bold"
          } p-2 text-[14px] text-[#2c2c2c] cursor-text outline-0 focus:outline-none focus:ring-0   focus:border-0 border-0   bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full`}
          placeholder={`${title}`}
        />
        <img
          src={"/images/arrow-down-s-line.svg"}
          alt="drop-down"
          className="mt-0.5 pr-2 cursor-pointer"
        />
      </div>
      {isOpen && !disabled && (
        <>
          {lists && queryDeliverytype&& queryDeliverytype.toLowerCase() === "local" && (
            <ul
              className={`absolute z-50 px-3 py-2 border w-full ${
                lists.length > 0 ? "max-h-[300px]" : "h-[100px]"
              } overflow-y-scroll rounded-t-none border-t-0 border-l-0 border-r-0 shadow-lg bg-white  mt-1`}
            >
              {lists && lists?.length > 0 ? (
                lists.map((d: any, idx:number) => (
                  <li key={idx+1}>
                    <p className="bg-black text-white">{d.name}</p>
                    <p className="bg-[green] p-2">{d.zone}</p>
                    {d.locations && d?.locations.length > 0 ? (
                      d.locations?.map((option: any, idx:number) => (
                        <div
                          key={idx+1}
                          onClick={() => {
                            setZoneOption(d.zone);
                            handleOptionClick(option, d.state, d.hub);
                          }}
                          className="p-2 cursor-pointer hover:bg-gray-200"
                        >
                          {option.location}
                        </div>
                      ))
                    ) : (
                      <p className="text-[green]">No location available!!!</p>
                    )}
                  </li>
                ))
              ) : (
                <p className="text-[green]">No location available!!!</p>
              )}
            </ul>
          )}
          {intlLists && (
            <ul
              className={`absolute z-50 px-3 py-2 border w-full ${
                intlLists.length > 0 ? "h-[300px]" : "h-[100px]"
              } overflow-y-scroll rounded-t-none border-t-0 border-l-0 border-r-0 shadow-lg bg-white  mt-1`}
            >
              {intlLists && intlLists?.length > 0 ? (
                intlLists.map((d: any, idx:number) => (
                  <li
                    key={idx+1}
                    onClick={() => {
                      setZoneOption(d.name);
                      handleOptionClick(d);
                    }}
                    className="p-2 cursor-pointer hover:bg-gray-200"
                  >
                    {d.name}
                  </li>
                ))
              ) : (
                <>
                  {weight === "" && (
                    <p className="text-[red]">Select a weight</p>
                  )}
                  {errorList === "" && weight !== "" && (
                    <p className="text-[red]">Select a new zone</p>
                  )}
                  {intlLists?.length === 0 && weight !== "" && (
                    <p className="text-[green]">No location available!!!</p>
                  )}
                </>
              )}
            </ul>
          )}
          {countryLists && (
            <ul
              className={`absolute z-50 px-3 py-2 border w-full ${
                countryLists.length > 0 ? "h-[300px]" : "h-[100px]"
              } overflow-y-scroll rounded-t-none border-t-0 border-l-0 border-r-0 shadow-lg bg-white  mt-1`}
            >
              {countryLists && countryLists?.length > 0 ? (
                countryLists.map((d: any) => (
                  <li
                    key={d.id}
                    onClick={() => {
                      setZoneOption(d?.country.name);
                      handleOptionClick(d);
                    }}
                    className="p-2 cursor-pointer hover:bg-gray-200"
                  >
                    {d?.country.name}
                  </li>
                ))
              ) : (
                <p className="text-[green]">No country available!!!</p>
              )}
            </ul>
          )}
           {stateLists && queryDeliverytype && queryDeliverytype.toLowerCase() !== "local" && (
            <ul
              className={`absolute z-50 px-3 py-2 border w-full ${
                stateLists.length > 0 ? "h-[300px]" : "h-[100px]"
              } overflow-y-scroll rounded-t-none border-t-0 border-l-0 border-r-0 shadow-lg bg-white  mt-1`}
            >
              {stateLists && stateLists?.length > 0 ? (
                stateLists.map((d: any) => (
                  <li
                    key={d.id}
                    onClick={() => {
                      setZoneOption(d?.state);
                      handleOptionClick(d);
                    }}
                    className="p-2 cursor-pointer hover:bg-gray-200"
                  >
                    {d?.state}
                  </li>
                ))
              ) : (
                <p className="text-[green]">No zone available!!!</p>
              )}
            </ul>
          )}
          {itemLists && (
            <ul
              className={`absolute z-50 px-3 py-2 border w-full ${
                itemLists.length > 0 ? "h-[300px]" : "h-[100px]"
              } overflow-y-scroll rounded-t-none border-t-0 border-l-0 border-r-0 shadow-lg bg-white  mt-1`}
            >
              {itemLists && itemLists?.length > 0 ? (
                itemLists.map((d: any) => (
                  <li
                    key={d.id}
                    onClick={() => {
                      setZoneOption(d?.name);
                      handleOptionClick(d);
                    }}
                    className="p-2 cursor-pointer hover:bg-gray-200"
                  >
                    {d?.name}
                  </li>
                ))
              ) : (
                <p className="text-[green]">No item category available!!!</p>
              )}
            </ul>
          )}
        </>
      )}
    </div>
  );
};
