import { validateEmail } from "./validateEmail";

export const validateAndSetEmailError = (
    email: string,
    fieldName: "senderEmail" | "recipientEmail",
    setInfoErrorMessages: React.Dispatch<React.SetStateAction<any>>
  ) => {
    if (email !== "") {
      setInfoErrorMessages((prev:any) => ({
        ...prev,
        [fieldName]: validateEmail(email) === null ? "Invalid email format" : "",
      }));
    }
  };
  