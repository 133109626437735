export const generateRequestPayload = (
  queryDeliveryType: string,
  dispatchFirstData: any,
  costData: any,
  intlCostData: any,
  referralData: any,
  intlReferralData: any,
  referenceId: string,
  zoneOption: string,
  zoneOptionTwo: string,
  clientUserId: string,
  clientUserPhone: string
) => {
  const isLocal = queryDeliveryType.toLowerCase() === "local";

  return {
    requestType: isLocal ? "local" : "export",
    clientAddress: dispatchFirstData.pickUpAddress,
    recipientAddress:
      dispatchFirstData.dropOffAddress !== ""
        ? dispatchFirstData.dropOffAddress
        : dispatchFirstData.dropOffLockerAddress,
    recipientEmail: dispatchFirstData.recipientEmail,
    recipientName: dispatchFirstData.recipientName,
    recipientPhone: dispatchFirstData.recipientPhone,
    quantity: Number(dispatchFirstData.quantity),
    itemDescription: dispatchFirstData.itemDescription,
    orderDate: dispatchFirstData.pickUpDate,
    cost: isLocal ? costData?.discountedRate : intlCostData?.discountedRate,
    weight: Number(dispatchFirstData.weight),
    discount: isLocal ? costData?.discount : intlCostData?.discount,
    discountedCost: isLocal
      ? referralData?.discountedRate !== undefined &&
        referralData?.discountedRate !== ""
        ? referralData.discountedRate
        : costData?.discountedRate
      : intlReferralData?.discountedRate !== undefined &&
        intlReferralData?.discountedRate !== ""
      ? intlReferralData.discountedRate
      : intlCostData?.discountedRate,
    paymentStatus: "ppc",
    paymentReference: referenceId,
    additionalNote: dispatchFirstData.additionalNote,
    thirdparty: "true",
    thirdparty_sendersName: dispatchFirstData.senderName,
    thirdparty_sendersPhone: dispatchFirstData.senderPhone,
    clientEmail: dispatchFirstData.senderEmail,
    thirdparty_senders_Alt_Phone: dispatchFirstData.senderAltPhone,
    zoneID: Number(dispatchFirstData.pickUpZone),
    ZoneName: zoneOption,
    locationID: Number(dispatchFirstData.pickUpLocationId),
    locationName: dispatchFirstData.pickUpLocationName,
    dropzoneID: Number(dispatchFirstData.dropOffZone),
    dropOffId: dispatchFirstData.dropOffId,
    dropZoneName: zoneOptionTwo,
    droplocationID: Number(dispatchFirstData.dropOffLocationId),
    droplocationName: dispatchFirstData.dropOffLocationName,
    transportMode: "Bike",
    deliveryType: dispatchFirstData.deliveryType,
    valueOfItem: Number(dispatchFirstData.cost),
    expanded: false,
    clientName: clientUserId,
    clientPhone: clientUserPhone,
    source: "Fez Website",
    fragile: dispatchFirstData.fragile || false,
  };
};
