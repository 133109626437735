import { object } from "yup";

import { validateEmailField, validateNumberField, validateStringField

 } from "./validateField";

export const createUserSchema = (setInfoErrorMessages: any, query: string) =>
  object({
    dropOffZone: validateStringField("dropOffZone", {
      dropOffZone: "Dropoff zone is required",
    }, setInfoErrorMessages),

    pickUpZone: validateStringField("pickUpZone", {
      pickUpZone: "Pickup zone is required",
    }, setInfoErrorMessages),

    pickUpAddress: validateStringField("pickUpAddress", {
      pickUpAddress: "Pick up address is required",
    }, setInfoErrorMessages),

    dropOffAddress: validateStringField("dropOffAddress", {
      dropOffAddress: "Drop off address is required",
    }, setInfoErrorMessages),

    pickUpDate: validateStringField("pickUpDate", {
      pickUpDate: "Pick up date is required",
    }, setInfoErrorMessages),

    weight: validateNumberField("weight", {
      weight: "Weight must be a valid number",
    }, setInfoErrorMessages),
  });

export const createUserSchemaTwo = (setInfoErrorMessages: any, useSmartLocker: boolean) =>
  object({
    senderName: validateStringField("senderName", {
      senderName: "Sender name is required",
    }, setInfoErrorMessages),

    recipientName: validateStringField("recipientName", {
      recipientName: "Recipient name is required",
    }, setInfoErrorMessages),

    itemDescription: validateStringField("itemDescription", {
      itemDescription: "Item description is required",
    }, setInfoErrorMessages),

    senderPhone: validateNumberField("senderPhone", {
      senderPhone: "Phone number must be a valid number",
    }, setInfoErrorMessages),

    recipientPhone: validateNumberField("recipientPhone", {
      recipientPhone: "Phone number must be a valid number",
    }, setInfoErrorMessages),

    quantity: validateNumberField("quantity", {
      quantity: "Quantity must be a valid number",
    }, setInfoErrorMessages),

    cost: validateNumberField("cost", {
      cost: "Cost must be a valid number",
    }, setInfoErrorMessages),

    senderEmail: validateEmailField("senderEmail", {
      senderEmail: "Email is required",
    }, setInfoErrorMessages),

    recipientEmail: validateEmailField("recipientEmail", {
      recipientEmail: "Email is required",
    }, setInfoErrorMessages, useSmartLocker),
  });

  export const createImportUserSchemaTwo = (setInfoErrorMessages: any) =>
   object({
      country: validateStringField("country", { country: "Country is required" }, setInfoErrorMessages),
      item: validateStringField("item", { item: "Item is required" }, setInfoErrorMessages),
      dropOffZone: validateStringField("dropOffZone", { dropOffZone: "Dropoff zone is required" }, setInfoErrorMessages),
      dropOffAddress: validateStringField("dropOffAddress", { dropOffAddress: "Drop off address is required" }, setInfoErrorMessages),
      orderNumber: validateStringField("orderNumber", { orderNumber: "Order Number is required" }, setInfoErrorMessages),
      vendorName: validateStringField("vendorName", { vendorName: "Vendor/ Business Name is required" }, setInfoErrorMessages),
      itemName: validateStringField("itemName", { itemName: "Item Name is required" }, setInfoErrorMessages),
      weight: validateNumberField("weight", { weight: "Weight must be a valid number" }, setInfoErrorMessages),
    });




