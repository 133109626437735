import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { toast } from "react-toastify";

const generateRandomNumbersWithTimestamp = () => {
  return Math.floor(Math.random() * 9000000000000) + 1000000000000;
};

export const generateRandomDataWithDelay = () => {
  return generateRandomNumbersWithTimestamp();
};

export const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const scrollToTop = () => {
  // Temporarily remove overflow-x-hidden
  document.body.style.overflowX = "auto";

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  // Restore overflow-x-hidden after scrolling
  setTimeout(() => {
    document.body.style.overflowX = "hidden";
  }, 1000); // Adjust the delay as needed
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const checkDateRange = (date: Date | null): boolean => {
  if (!date) return false; // Return false if the date is null
  
  const selectedDate = new Date(date);
  const selectedDay = selectedDate.getDate();
  const selectedMonth = selectedDate.getMonth(); 

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  // Calculate the start of range (25th of the current month)
  const startOfRange = new Date(currentDate.getFullYear(), currentMonth, 25);

  // Calculate the end of range (5th of the next month)
  let endOfRange: Date;
  if (currentMonth === 11) {
    // December case
    endOfRange = new Date(currentDate.getFullYear() + 1, 0, 5); // January of next year
  } else {
    endOfRange = new Date(currentDate.getFullYear(), currentMonth + 1, 5);
  }

  if (
    (selectedMonth === startOfRange.getMonth() && selectedDay >= 25) ||
    (selectedMonth === endOfRange.getMonth() && selectedDay <= 5)
  ) {
  
    return true;
  }

  return false;
};

export function isFridayAt2PM(date: Date) {
  const dayOfWeek = date.getDay(); 
  const hours = date.getHours();
  return dayOfWeek === 5 && hours >= 14 && hours < 23;
}





interface ErrorData {
    description: string;
  }
const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
    return typeof error === "object" && error !== null && "status" in error;
  };
  const hasErrorData = (
    error: FetchBaseQueryError
  ): error is FetchBaseQueryError & { data: ErrorData } => {
    return "data" in error && typeof error.data === "object";
  };
  export const handleErrorToast = (error: any) => {
    if (error?.data?.description) {
      toast.error(error.data.description, {
        className: "toast-error",
        progressClassName: "Toastify__progress-bar--error",
      });
    } else if (isFetchBaseQueryError(error) && hasErrorData(error)) {
      toast.error(error.data.description, {
        className: "toast-error",
        progressClassName: "Toastify__progress-bar--error",
      });
    } 
    else {
      toast.error("An unknown error occurred, please try again", {
        className: "toast-error",
        progressClassName: "Toastify__progress-bar--error",
      });
    }
  };

export const handleSuccessToast = (message: string) => {
  
    toast.success(message, {
      className: "toast-success",
    });
  };