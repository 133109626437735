export const updateDropOffDetails = (
    value: string,
    label: string,
    setDispatchFirstData: React.Dispatch<React.SetStateAction<any>>,
    setInfoErrorMessages: React.Dispatch<React.SetStateAction<any>>
  ) => {
    setDispatchFirstData((prev:any) => ({
      ...prev,
      dropOffLocker: value,
      dropOffLockerAddress: label,
      dropOffAddress: "",
    }));
  
    setInfoErrorMessages((prev:any) => ({
      ...prev,
      dropOffLocker: "",
      dropOffAddress: "",
    }));
  };
  