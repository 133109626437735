import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const searchAddressApi = createApi({
  reducerPath: "searchUserAddressApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    credentials: "same-origin",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUserAddress: builder.query<any, string>({
      query: (userSearchAddress) => ({
        url: "v1/map/search",
        params: { search: userSearchAddress },
        method: "GET",
      }),
    }),
    postDropOffProxy: builder.mutation<
      any,
      { longitude: string; latitude: string; radius?: string }
    >({
      query: (dropOffData) => ({
        url: "v1/drop-offs/proxy",
        method: "POST",
        body: dropOffData,
      }),
    }),
  }),
});

export const { useGetUserAddressQuery, usePostDropOffProxyMutation } =
  searchAddressApi;
