export const handleFocusValidation = (
    query: string,
    field: "pickUpZone" | "dropOffZone",
    setInfoErrorMessages: React.Dispatch<
      React.SetStateAction<any>
    >
  ) => {
    setInfoErrorMessages((prev:any) => ({
      ...prev,
      [field]: query === "" ? `${field === "pickUpZone" ? "Pick up" : "Drop off"} zone is required` : "",
    }));
  };
  