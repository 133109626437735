import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import { InformationLayout } from "../../InfoLayout";
import { DropDownSelect, SearchableDropdown } from "../../form/Dropdown";
import { Input } from "../../form/Input";
import SelectInput from "../../form/SelectInput";
import { Button } from "../../Button";

interface StepOneProps {
  setShowTerms: React.Dispatch<React.SetStateAction<boolean>>;
  setQueryDeliveryType: React.Dispatch<React.SetStateAction<string>>;
  setIsOpenWeight: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenDeliveryType: React.Dispatch<React.SetStateAction<boolean>>;
  queryDeliverytype: string;
  errorCost: string;
  list: any;
  isOpenDeliveryType: boolean;
  infoErrorMessages: any;
  startDate: any;
  onFocusDate: () => void;
  onChangeDate: (selected: any) => void;
  handleWeightType: (e: any) => void;
  intlWeightData: any;
  isOpenWeight: boolean;
  weight: any;
  dispatchFirstData: any;
  filteredOptions: any;
  filteredOptionsTwo: any;
  toggleDropdown: () => void;
  handleOptionClick: (option: any, state: any) => void;
  focusHandlePickUp: () => void;
  handleChange: (e: any) => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  query: string;
  setZoneOption: React.Dispatch<React.SetStateAction<any>>;
  filteredDropOffOptions: any;
  handleOptionClickTwo: (option: any, state: any) => void;
  focusHandleDropOff: () => void;
  isOpenTwo: boolean;
  setIsOpenTwo: React.Dispatch<React.SetStateAction<boolean>>;
  setQueryTwo: React.Dispatch<React.SetStateAction<string>>;
  queryTwo: string;
  setZoneOptionTwo: React.Dispatch<React.SetStateAction<any>>;
  dropOffState: string;
  pickUpState: string;
  isLockerLoading: boolean;
  useSmartLocker: boolean;
  setUseSmartLocker: React.Dispatch<React.SetStateAction<boolean>>;
  setDispatchFirstData: React.Dispatch<React.SetStateAction<any>>;
  lockerData: any;
  lockerAvailabilityData: any;
  isLockerAvailabilityLoading: boolean;
  onFinishStepOne: () => void;
  filteredIntLocations: any;
  handleSelectLocker: (value: string, label: string) => void;
  isCostLoading: boolean;
  handleIntlOption: (option: any) => void;
  isLoadingIntlCost: boolean;
}

const StepOne = ({
  toggleDropdown,
  handleOptionClick,
  focusHandlePickUp,
  setIsOpen,
  isOpen,
  setQuery,
  setZoneOption,
  setShowTerms,
  onFocusDate,
  errorCost,
  infoErrorMessages,
  list,
  startDate,
  query,
  setQueryDeliveryType,
  queryDeliverytype,
  isOpenDeliveryType,
  onChangeDate,
  intlWeightData,
  setIsOpenWeight,
  isOpenWeight,
  handleWeightType,
  setIsOpenDeliveryType,
  weight,
  filteredOptions,
  filteredOptionsTwo,
  dispatchFirstData,
  handleChange,
  filteredDropOffOptions,
  handleOptionClickTwo,
  focusHandleDropOff,
  isOpenTwo,
  setIsOpenTwo,
  setQueryTwo,
  queryTwo,
  setZoneOptionTwo,
  dropOffState,
  pickUpState,
  isLockerLoading,
  useSmartLocker,
  lockerData,
  setUseSmartLocker,
  setDispatchFirstData,
  lockerAvailabilityData,
  isLockerAvailabilityLoading,
  onFinishStepOne,
  isCostLoading,
  isLoadingIntlCost,
  handleSelectLocker,
  filteredIntLocations,
  handleIntlOption,
}: StepOneProps) => {
  return (
    <div className=" mb-32 ">
      <div className="flex md:flex-row flex-col md:space-y-0 space-y-20 bg-[#EFF6EE] px-5 min-h-[700px] pt-24 sm:px-10 xl:px-20 md:space-x-5  md:items-center md:justify-between  ">
        <div className="animate__animated animate__fadeInTopLeft">
          <h2 className="xl:text-[69px] font-ppmigra lg:text-[60px] text-[40px] md:text-[50px] whitespace-nowrap text-textColor font-bold">
            Dispatch Now
          </h2>
          <p className="text-[18px] xl:block hidden font-[500]">
            Our smart solution can help meet your delivery <br /> needs in a
            quick and convenient manner.
          </p>
          <p className="text-[18px] font-onestMedium xl:hidden block ">
            Our smart solution can help meet your delivery needs in a quick and
            convenient manner.
          </p>
        </div>
        <div className="animate__animated animate__fadeInTopRight">
          <img src="/images/all-car.svg" alt="all-car" />
        </div>
      </div>
      <div className="mt-5  w-full px-5 sm:px-10 xl:px-20 md:flex-row flex-col md:space-y-0 space-y-5   flex md:justify-between ">
        <div className=" w-full ">
          <h3 className="md:text-[32px] text-[24px] font-ppmigra text-textColor">
            Smart delivery solution
          </h3>
          <InformationLayout onClick={() => setShowTerms(true)} />
        </div>

        <div className="w-full ">
          <h4 className="text-textColor font-onestBold text-[20px]">
            Delivery details
          </h4>
          {errorCost !== "" && (
            <div className="bg-[#f19797] border-[red]  border max-w-[500px] px-5 py-2 rounded-md">
              <p className="text-white items-center flex text-[14px]  font-onestMedium">
                {errorCost}
              </p>
            </div>
          )}
          <div className="mt-5 space-y-3 max-w-[500px]">
            <DropDownSelect
              placeholder="Select delivery type"
              lists={list}
              // toggleDropdown={() =>
              //   setIsOpenDeliveryType(!isOpenDeliveryType)
              // }
              isRequired={true}
              handleOptionClick={() => {}}
              isOpen={isOpenDeliveryType}
              // setIsOpen={setIsOpenDeliveryType}
              setQuery={setQueryDeliveryType}
              query={queryDeliverytype}
              name="deliveryType"
              title="Select Delivery Type"
              label={"Delivery type"}
              disabled={true}
            />
            {queryDeliverytype.toLowerCase() === "local" && (
              <DropDownSelect
                placeholder="Select delivery mode"
                // lists={list}
                // toggleDropdown={() =>
                //   setIsOpenDeliveryType(!isOpenDeliveryType)
                // }
                disabled={true}
                // handleOptionClick={handleOptionClickThree}
                isOpen={isOpenDeliveryType}
                // setIsOpen={setIsOpenDeliveryType}
                // setQuery={setQueryDeliveryType}
                query={"Bike"}
                name="deliveryMode"
                title="Select Delivery Mode"
                className="mb-10"
                label={"Delivery type"}
              />
            )}
            <div>
              <p className="text-[#000000] items-center flex mt-5 text-[14px]  font-onestMedium">
                Pick up Date <span className="text-red-500 text-[20px]">*</span>
              </p>
              <DatePicker
                name="pickUpDate"
                selected={startDate}
                onFocus={onFocusDate}
                minDate={moment().toDate()}
                onChange={onChangeDate}
                className={`text-grey p-2  text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border-2  border-inputBorderGreen  focus:border-2  focus:border-border-2 focus:border-inputBorderGreen rounded-[8px] bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full`}
              />
              {infoErrorMessages.pickUpDate && (
                <p className="text-red-500 text-[12px]">
                  {infoErrorMessages.pickUpDate}
                </p>
              )}
            </div>
            {queryDeliverytype.toLowerCase() !== "local" && (
              <div>
                <DropDownSelect
                  placeholder="Select Weight"
                  lists={intlWeightData?.weight}
                  toggleDropdown={() => setIsOpenWeight(!isOpenWeight)}
                  isRequired
                  handleOptionClick={handleWeightType}
                  isOpen={isOpenWeight}
                  setIsOpen={setIsOpenDeliveryType}
                  setQuery={setQueryDeliveryType}
                  query={weight.name}
                  name="weight"
                  title="Select weight"
                  label={"weight type"}
                />
                {infoErrorMessages.weight && (
                  <p className="text-red-500 text-[12px]">
                    {infoErrorMessages.weight}
                  </p>
                )}
              </div>
            )}

            <div>
              <SearchableDropdown
                name="pickUpZone"
                lists={filteredOptions}
                stateLists={filteredOptionsTwo}
                toggleDropdown={toggleDropdown}
                handleOptionClick={handleOptionClick}
                title="Select Pick-up Zone"
                label={"Pickup zone"}
                isOpen={isOpen}
                onFocus={focusHandlePickUp}
                setIsOpen={setIsOpen}
                setQuery={setQuery}
                query={query}
                queryDeliverytype={queryDeliverytype}
                // disabled={queryDeliverytype.toLowerCase() !== "local"}
                isRequired
                setZoneOption={setZoneOption}
              />

              {infoErrorMessages.pickUpZone && (
                <p className="text-red-500 text-[12px]">
                  {infoErrorMessages.pickUpZone}
                </p>
              )}
            </div>

            <div>
              <Input
                type="text"
                name="pickUpAddress"
                value={dispatchFirstData.pickUpAddress}
                onChange={handleChange}
                label="Pick-up Address"
                isRequired
                placeholder="Enter address"
                labelFor="dropoffAddress"
              />

              {infoErrorMessages?.pickUpAddress && (
                <p className="text-red-500 text-[12px]">
                  {infoErrorMessages.pickUpAddress}
                </p>
              )}
            </div>

            <div>
              {queryDeliverytype.toLowerCase() === "local" ? (
                <SearchableDropdown
                  lists={filteredDropOffOptions}
                  handleOptionClick={handleOptionClickTwo}
                  onFocus={focusHandleDropOff}
                  title="Select Drop-off Zone"
                  label={"Drop Zone"}
                  isOpen={isOpenTwo}
                  name="dropOffZone"
                  setIsOpen={setIsOpenTwo}
                  setQuery={setQueryTwo}
                  query={queryTwo}
                  isRequired
                  setZoneOption={setZoneOptionTwo}
                  queryDeliverytype="local"
                  toggleDropdown={() => setIsOpenTwo(!isOpenTwo)}
                />
              ) : (
                <SearchableDropdown
                  intlLists={filteredIntLocations}
                  handleOptionClick={handleIntlOption}
                  onFocus={focusHandleDropOff}
                  title="Select Drop-off Zone"
                  label={"Drop-off Zone"}
                  isOpen={isOpenTwo}
                  name="dropOffZone"
                  setIsOpen={setIsOpenTwo}
                  setQuery={setQueryTwo}
                  weight={dispatchFirstData.weight}
                  query={queryTwo}
                  isRequired
                  errorList={dispatchFirstData.dropOffZone}
                  setZoneOption={setZoneOptionTwo}
                  toggleDropdown={() => setIsOpenTwo(!isOpenTwo)}
                />
              )}
              {infoErrorMessages.dropOffZone && (
                <p className="text-red-500 text-[12px]">
                  {infoErrorMessages.dropOffZone}
                </p>
              )}
            </div>
            {dropOffState !== null &&
              dropOffState !== undefined &&
              pickUpState !== null &&
              pickUpState !== undefined &&
              dropOffState === pickUpState && (
                <>
                  {isLockerLoading && <p>....searching for dropoff locker</p>}
                  {queryDeliverytype.toLowerCase() === "local" &&
                    lockerData?.Lockers?.length > 0 && (
                      <div className="flex items-center space-x-1">
                        <input
                          type="checkbox"
                          checked={useSmartLocker}
                          required={true}
                          className="cursor-pointer"
                          onChange={(e: any) => {
                            setUseSmartLocker(e.target.checked);
                            setDispatchFirstData((prev: any) => ({
                              ...prev,
                              dropOffAddress: "",
                              dropOffLockerAddress: "",
                            }));
                          }}
                        />
                        <p className="text-sm font-onestMedium">
                          Send to our smart Locker (Enjoy up to 50% discount)
                        </p>
                      </div>
                    )}
                </>
              )}
            {!useSmartLocker && (
              <div>
                <Input
                  type="text"
                  value={dispatchFirstData.dropOffAddress}
                  onChange={handleChange}
                  label="Recipient Address"
                  name="dropOffAddress"
                  placeholder="Enter address"
                  labelFor="dropoffAddress"
                  isRequired
                />
                {infoErrorMessages.dropOffAddress && (
                  <p className="text-red-500 text-[12px]">
                    {infoErrorMessages.dropOffAddress}
                  </p>
                )}
              </div>
            )}

            {useSmartLocker && (
              <div className="mt-3">
                <SelectInput
                  searchLoading={isLockerLoading}
                  value={dispatchFirstData?.dropOffLockerAddress}
                  name="dropOffLocker"
                  placeholder={"Select Drop-off Address"}
                  handleChange={(name, value, label) =>
                    handleSelectLocker(value, label)
                  }
                  label={"Select Drop-off Address"}
                  dropdownOptions={lockerData?.Lockers.map((item: any) => ({
                    label: item?.lockerAddress,
                    value: item?.lockerID,
                  }))}
                />
                {infoErrorMessages.dropOffLocker && (
                  <p className="text-red-500 text-[12px]">
                    {infoErrorMessages.dropOffLocker}
                  </p>
                )}
              </div>
            )}

            {!lockerAvailabilityData?.Available &&
              useSmartLocker &&
              !isLockerAvailabilityLoading &&
              dispatchFirstData?.dropOffLockerAddress && (
                <div>
                  <p className="text-[red] text-sm">
                    {" "}
                    It appears the locker selected is at capacity, please select
                    another locker or{" "}
                    <span
                      className="underline cursor-pointer font-onestMedium"
                      onClick={(e: any) => {
                        setUseSmartLocker(false);
                        setDispatchFirstData((prev: any) => ({
                          ...prev,
                          dropOffAddress: "",
                          dropOffLockerAddress: "",
                        }));
                      }}
                    >
                      click here
                    </span>{" "}
                    to fill a drop off address instead
                  </p>
                </div>
              )}
            {isLockerAvailabilityLoading && (
              <p>....searching for available locker</p>
            )}
            {queryDeliverytype.toLowerCase() === "local" && (
              <div>
                <Input
                  type="text"
                  label="Weight"
                  placeholder=""
                  className="w-full"
                  weightShow
                  name="weight"
                  isRequired
                  value={dispatchFirstData.weight}
                  onChange={handleChange}
                />
                {infoErrorMessages.weight && (
                  <p className="text-red-500 text-[12px]">
                    {infoErrorMessages.weight}
                  </p>
                )}
              </div>
            )}
          </div>

          <div className="mt-10 max-w-[500px] ">
            <Button
              title="Next"
              className="!py-3"
              onClick={onFinishStepOne}
              disabled={isCostLoading || isLoadingIntlCost}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
