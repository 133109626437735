export const handleWeightSelection = (
    weightData: { id: null; name: string },
    setWeight: React.Dispatch<React.SetStateAction<{ id: null; name: string }>>,
    setDispatchFirstData: React.Dispatch<React.SetStateAction<any>>,
    setInfoErrorMessages: React.Dispatch<React.SetStateAction<any>>,
    setIsOpenWeight: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (weightData.id) {
      setInfoErrorMessages((prev:any) => ({ ...prev, weight: "" }));
    }
  
    setWeight(weightData);
  
    setDispatchFirstData((prev:any) => ({
      ...prev,
      weight: weightData.name,
    }));
  
    setIsOpenWeight(false);
  };
  