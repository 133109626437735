export const scrollToTop = () => {
    // Temporarily remove overflow-x-hidden
    document.body.style.overflowX = "auto";
  
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  
    // Restore overflow-x-hidden after scrolling
    setTimeout(() => {
      document.body.style.overflowX = "hidden";
    }, 1000); // Adjust the delay as needed
  };