export const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  
  export function isFridayAt2PM(date: Date) {
    const dayOfWeek = date.getDay(); 
    const hours = date.getHours();
    return dayOfWeek === 5 && hours >= 14 && hours < 23;
  }