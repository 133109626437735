export const validateLockerLimits = (
    lockerData: { maxWeight: number; maxValueOfItem: number } | null,
    dispatchData: { dropOffLocker?: string; weight?: string; cost?: string },
    setInfoErrorMessages: React.Dispatch<
      React.SetStateAction<any>
    >
  ) => {
    if (!lockerData || !dispatchData?.dropOffLocker) return;
    if (
      lockerData &&
      dispatchData?.dropOffLocker !== "" &&
      dispatchData?.weight !== ""
    ){
      if (dispatchData?.weight && lockerData.maxWeight < Number(dispatchData.weight)) {
        setInfoErrorMessages((prev:any) => ({
          ...prev,
          weight: "Weight is more than locker limit",
        }));
      }
    }
   
    if (
      lockerData &&
      dispatchData?.dropOffLocker !== "" &&
      dispatchData?.cost !== ""
    ) {
    if (dispatchData?.cost && lockerData.maxValueOfItem < Number(dispatchData.cost)) {
      setInfoErrorMessages((prev:any) => ({
        ...prev,
        cost: "Value of item is more than locker value of item",
      }));
    }
  }
  };
  