export const handleCopy = (
    address: string,
    setCopiedAdd: React.Dispatch<React.SetStateAction<string>>,
    setShowClipboard: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setCopiedAdd(address);
  
    navigator.clipboard
      .writeText(address)
      .then(() => {
        setShowClipboard(true);
  
        setTimeout(() => {
          setShowClipboard(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };
  