import React from "react";
import { InformationLayout } from "../../InfoLayout";
import "react-datepicker/dist/react-datepicker.css";
import { SectionBack } from "../../SectionBack";
import { Input } from "../../form/Input";
import { Button } from "../../Button";
import { getReferralMessage } from "../../../helper/getReferralMessage";

interface StepThreeProps {
  setShowTerms: React.Dispatch<React.SetStateAction<boolean>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  dispatchFirstData: any;
  step: number;
  handleChange: (e: any) => void;
  onApplyReferral: () => void;
  getIntlReferralCostData: () => void;
  isMultipleLoading: boolean;
  setApplyReferral: React.Dispatch<React.SetStateAction<boolean>>;
  isReferralLoading: boolean;
  isLoadingReferralCost: boolean;
  referralData: any;
  queryDeliverytype: string;
  costData: any;
  intlReferralData: any;
  deleteLoading: boolean;
  regenerate: boolean;
  deleteDataOrder: () => void;
  getMultipleData: () => void;
  intlCostData: any;
  setApplyReferralTwo: React.Dispatch<React.SetStateAction<boolean>>;
}

const StepThree = ({
  setStep,
  setShowTerms,
  queryDeliverytype,
  handleChange,
  dispatchFirstData,
  intlCostData,
  isMultipleLoading,
  referralData,
  intlReferralData,
  isReferralLoading,
  costData,
  step,
  setApplyReferral,
  onApplyReferral,
  setApplyReferralTwo,
  isLoadingReferralCost,
  getIntlReferralCostData,
  deleteLoading,
  regenerate,
  deleteDataOrder,
  getMultipleData,
}: StepThreeProps) => {

  return (
    <div className=" mb-32 ">
      <SectionBack
        imgStr="/images/all-car.svg"
        onClick={() => setStep(step - 1)}
        content=" Dispatch Now"
      />
      <div className="mt-10  w-full px-5 sm:px-10 xl:px-20 md:flex-row flex-col md:space-y-0 space-y-5   flex md:justify-between ">
        <div className=" w-full ">
          <h3 className="md:text-[32px] text-[24px] font-ppmigra text-textColor">
            Smart delivery solution
          </h3>
          <InformationLayout onClick={() => setShowTerms(true)} />
        </div>
        <div className="w-full ">
          <div className="mt-3 space-y-3 max-w-[500px]">
            <p className="text-textGreen font-onestBold text-[16px] ">
              TOTAL COST
            </p>
            <p className="font-onestRegular text-[14px] md:text-[15px]">
              The total estimated cost for your delivery is
            </p>
            <div className="border rounded-[8px] px-3 py-3 border-[#C2C5C2] shadow  drop-shadow-2xl  shadow-shadow">
              {queryDeliverytype.toLowerCase() === "local" ? (
                <>
                  {referralData?.status === "00" &&
                  referralData?.discountedRate !== "" ? (
                    <div className="flex space-x-3">
                      <h2
                        className={`line-through text-red-500 font-onestBold text-[28px] md:text-[34px]`}
                      >
                        {` ₦ ${new Intl.NumberFormat().format(
                          Number(costData?.discountedRate)
                        )}`}
                      </h2>
                      <h2 className="font-onestBold text-textGreen text-[28px] md:text-[34px]">
                        {` ₦ ${new Intl.NumberFormat().format(
                          Number(referralData?.discountedRate)
                        )}`}
                      </h2>
                    </div>
                  ) : (
                    <>
                      {costData?.rate !== costData?.discountedRate! ? (
                        <div className="flex space-x-3">
                          <h2
                            className={`line-through text-red-500 font-onestBold text-[28px] md:text-[34px]`}
                          >
                            {` ₦ ${new Intl.NumberFormat().format(
                              Number(costData?.rate)
                            )}`}
                          </h2>
                          <h2 className="font-onestBold text-textGreen text-[28px] md:text-[34px]">
                            {` ₦ ${new Intl.NumberFormat().format(
                              Number(costData?.discountedRate)
                            )}`}
                            <span className="block text-textGreen text-lg leading-tight w-4/5">
                              20% drop-off discount applied!
                            </span>
                          </h2>
                        </div>
                      ) : (
                        <div className="flex space-x-3">
                          <h2 className="font-onestBold text-[28px] md:text-[34px]">
                            ₦{" "}
                            {new Intl.NumberFormat().format(
                              Number(costData?.discountedRate)
                            )}
                          </h2>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {intlReferralData?.status === "00" &&
                  intlReferralData?.discountedRate !== "" ? (
                    <div className="flex space-x-3">
                      <h2
                        className={`line-through text-red-500 font-onestBold text-[28px] md:text-[34px]`}
                      >
                        {` ₦ ${new Intl.NumberFormat().format(
                          Number(intlCostData?.discountedRate)
                        )}`}
                      </h2>
                      <h2 className="font-onestBold text-textGreen text-[28px] md:text-[34px]">
                        {` ₦ ${new Intl.NumberFormat().format(
                          Number(intlReferralData?.discountedRate)
                        )}`}
                      </h2>
                    </div>
                  ) : (
                    <div className="flex space-x-3">
                      {Number(intlCostData?.discountedRate) !==
                      Number(intlCostData?.rate) ? (
                        <>
                          <h2
                            className={`line-through text-red-500 font-onestBold text-[28px] md:text-[34px]`}
                          >
                            {` ₦ ${new Intl.NumberFormat().format(
                              Number(intlCostData?.rate)
                            )}`}
                          </h2>
                          <h2 className="font-onestBold text-textGreen text-[28px] md:text-[34px]">
                            {` ₦ ${new Intl.NumberFormat().format(
                              Number(intlCostData?.discountedRate)
                            )}`}
                            <span className="block text-textGreen text-lg leading-tight w-4/5">
                              20% drop-off discount applied!
                            </span>
                          </h2>
                        </>
                      ) : (
                        <h2
                          className={` text-textGreen font-onestBold text-[28px] md:text-[34px]`}
                        >
                          {` ₦ ${new Intl.NumberFormat().format(
                            Number(intlCostData?.discountedRate)
                          )}`}
                        </h2>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {queryDeliverytype?.toLowerCase() === "local" && (
            <div className="max-w-[500px] mt-5">
              <p className="mb-3">Do you have Referral Code?</p>
              <div className="flex items-center space-x-2">
                <Input
                  type="text"
                  value={dispatchFirstData.referralCode}
                  onChange={handleChange}
                  label="Referral Code"
                  name="referralCode"
                  placeholder="Enter referral code"
                  labelFor="referralCode"
                />
                <Button
                  title="Apply"
                  loading={isReferralLoading}
                  className="mt-5 border border-btnColor !bg-[#E8FFE5]"
                  onClick={() => {
                    setApplyReferral(true);
                    onApplyReferral();
                  }}
                />
              </div>
              {referralData?.status && (
                <p
                  className={`${
                    referralData?.status === "00"
                      ? "text-green-600"
                      : "text-red-500"
                  }text-green-600 text-[12px]`}
                >
                  {getReferralMessage(referralData)}
                </p>
              )}
            </div>
          )}
          {queryDeliverytype?.toLowerCase() !== "local" && (
            <div className="max-w-[500px] mt-5">
              <p className="mb-3">Do you have Referral Code?</p>
              <div className="flex items-center space-x-2">
                <Input
                  type="text"
                  value={dispatchFirstData.referralCode}
                  onChange={handleChange}
                  label="Referral Code"
                  name="referralCode"
                  placeholder="Enter referral code"
                  labelFor="referralCode"
                />
                <Button
                  title="Apply"
                  loading={isLoadingReferralCost}
                  className="mt-5 border border-btnColor !bg-[#E8FFE5]"
                  onClick={() => {
                    setApplyReferralTwo(true);
                    getIntlReferralCostData();
                  }}
                />
              </div>
              <p
                className={`${
                  intlReferralData?.status === "00"
                    ? "text-green-600"
                    : "text-red-500"
                }text-green-600 text-[12px]`}
              >
                {getReferralMessage(intlReferralData)}
              </p>
            </div>
          )}
          <div className="mt-10 space-y-2 max-w-[500px] ">
            <Button
              title="Pay"
              loading={isMultipleLoading || deleteLoading}
              className="!py-3"
              onClick={() => {
                if (regenerate) {
                  deleteDataOrder();
                } else {
                  getMultipleData();
                }
              }}
            />
            <Button
              title="Back"
              disabled={isMultipleLoading || deleteLoading}
              className="!py-3 !bg-transparent"
              onClick={() => setStep(step - 1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepThree;
