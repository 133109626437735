export const checkShowOrderError = (
    costData: any,
    intlCostData: any,
    setShowOrderError: (status: boolean) => void
  ) => {
    const isOrderLocked =
      costData?.orderLocked === "YF" ||
      costData?.orderLocked === "YS" ||
      costData?.orderLocked === "Y" ||
      intlCostData?.orderLocked === "YF" ||
      intlCostData?.orderLocked === "YS" ||
      intlCostData?.orderLocked === "Y";
  
    const hasErrorStatus =
      costData?.status === "Error" ||
      intlCostData?.status === "Error" ||
      (costData?.status === "Error" && costData?.orderLocked === "Y") ||
      (intlCostData?.status === "Error" && intlCostData?.orderLocked === "Y") ||
      costData?.orderLocked === "D" ||
      intlCostData?.orderLocked === "D";
  
    if (isOrderLocked || hasErrorStatus) {
      setShowOrderError(true);
    }
  };
  

  export const checkShowOrderErrorThree = (
    costDataResult: any,
    setShowOrderErrorThree: (status: boolean) => void
  ) => {
    if (
      (costDataResult?.orderLocked !== "YF" &&
        costDataResult?.orderLocked !== "YS" &&
        costDataResult?.orderLocked !== "Y") &&
      costDataResult?.status === "Error" &&
      costDataResult?.orderLocked !== "D"
    ) {
      setShowOrderErrorThree(true);
    }
  };
  
  export const checkShowOrderErrorOne = (
    costData: any,
    intlCostData: any,
    setShowOrderErrorOne: (status: boolean) => void
  ) => {
    const isOrderLocked =
      costData?.orderLocked === "YF" ||
      costData?.orderLocked === "YS" ||
      costData?.orderLocked === "Y" ||
      intlCostData?.orderLocked === "YF" ||
      intlCostData?.orderLocked === "YS" ||
      intlCostData?.orderLocked === "Y";
  
    if (isOrderLocked) {
      setShowOrderErrorOne(true);
    }
  };
  