/* eslint-disable */
import { useEffect, useState } from "react";
import { PageLayout } from "../components/Layout";
import { ToastContainer, toast } from "react-toastify";
import "animate.css";
import {
  DashboardModalAction,
  ErrorModal,
  ShowErrorandYS,
  ShowOrderErrorandD,
  ShowOrderLockYF,
  ShowTermsModal,
} from "../components/Modal";
import { useGetDropOffZonesQuery } from "../services/slices/getZonesSlice";
import {
  useGetImportLocationsQuery,
  useGetItemsCategoryQuery,
} from "../services/slices/import";
import {
  useGetImportCostPriceMutation,
  useGetImportReferralPriceMutation,
} from "../services/slices/costSlice";
import {
  useCreateImportOrderMutation,
  useUpdateReferenceMutation,
} from "../services/slices/multiple";
import moment from "moment";
import { devTerms } from "../components/termsAndCondition";
import { scrollToTop } from "../helper/scrollToTop";
import { validateEmail } from "../helper/validateEmail";
import ImportStepOne from "../components/dispatch/import/StepOne";
import ImportStepTwo from "../components/dispatch/import/StepTwo";
import ImportStepThree from "../components/dispatch/import/StepThree";
import {
  importInitialDispatchValues,
  importInitialValues,
} from "../constant/dispatchInitialValues";
import {
  createImportUserSchemaTwo,
  createUserSchemaTwo,
} from "../helper/schema";
import { IntlProps, RefProps } from "../type";

const Import = () => {
  const [showTerms, setShowTerms] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [queryTwo, setQueryTwo] = useState("");
  const [queryThree, setQueryThree] = useState("");
  const [isOpenTwo, setIsOpenTwo] = useState(false);
  const [isOpenThree, setIsOpenThree] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [dropOffZoneData, setDropOffZoneData] = useState([]);
  const [showAddresses, setShowAddresses] = useState<any>([]);
  const [locationData, setLocationData] = useState<any>([]);
  const [itemCategory, setItemCategory] = useState<any>([]);
  const { data: dropOffData } = useGetDropOffZonesQuery();
  const [orderStatus, setOrderStatus] = useState(true);
  const [showOrderError, setShowOrderError] = useState(false);
  const [showOrderErrorOne, setShowOrderErrorOne] = useState(false);
  const [showOrderErrorThree, setShowOrderErrorThree] = useState(false);
  const [zoneOptionThree, setZoneOptionThree] = useState<any>(null);
  const [errorCost, setErrorCost] = useState("");
  const [costError, setCostError] = useState("no");
  const [clientUserId, setClientUserId] = useState("");
  const [copiedAdd, setCopiedAdd] = useState("");
  const [clientUserPhone, setClientUserPhone] = useState("");
  const [clientUserAddress, setClientUserAddress] = useState("");
  const [showClipboard, setShowClipboard] = useState(false);
  const [applyReferral, setApplyReferral] = useState(false);
  const [currencyCode, setCurrencyCode] = useState("");
  const [updateReference] = useUpdateReferenceMutation();

  const [
    orderMultiple,
    {
      data: multipleData,
      isLoading: isMultipleLoading,
      isError: multipleError,
    },
  ] = useCreateImportOrderMutation();
  const [query, setQuery] = useState("");
  const [dispatchFirstData, setDispatchFirstData] =
    useState(importInitialValues);
  const [infoErrorMessages, setInfoErrorMessages] = useState(
    importInitialDispatchValues
  );
  const [
    getImportCostPrice,
    { data: costData, isLoading: isCostLoading, isError: isCostError },
  ] = useGetImportCostPriceMutation();
  const [
    getImportReferralPrice,
    { data: referralData, isLoading: isReferralLoading },
  ] = useGetImportReferralPriceMutation();

  const { data: importLocations } = useGetImportLocationsQuery();
  const { data: itemCategories } = useGetItemsCategoryQuery();
  const filteredDropOffOptions = dropOffZoneData.reduce(
    (acc: any, zone: any) => {
      const filteredLocations = zone.location.filter((location: any) =>
        location.location.toLowerCase().includes(queryThree?.toLowerCase())
      );

      if (filteredLocations.length > 0) {
        acc.push({
          zone: zone.name,
          locations: filteredLocations,
        });
      }

      return acc;
    },
    []
  );
  const userDetails: any = localStorage.getItem("fez-user");

  useEffect(() => {
    if (userDetails) {
      const { clientID, clientsPhone, clientsAddress } =
        JSON.parse(userDetails);
      setClientUserId(clientID);
      setClientUserPhone(clientsPhone);
      setClientUserAddress(clientsAddress);
    }
  }, [userDetails]);

  const handleChange = async (evt: any) => {
    const { name, value } = evt.target;
    setDispatchFirstData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "orderNumber" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, orderNumber: "" }));
    }
    if (name === "trackingId" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, orderNumber: "" }));
    }
    if (name === "orderNumber" && value === "") {
      if (name === "trackingId" && value === "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          orderNumber: "Order number is required",
        }));
      }
    }
    if (name === "weight" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, weight: "" }));
    }
    if (name === "itemName" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, itemName: "" }));
    }
    if (name === "vendorName" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, vendorName: "" }));
    }

    if (name === "dropOffAddress" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, dropOffAddress: "" }));
    }

    try {
      await createImportUserSchemaTwo(setInfoErrorMessages).validateAt(name, {
        [name]: value,
      });
    } catch (error: any) {
      if (error) {
        console.error(error);
      }
    }
  };

  const handleChangeTwo = async (evt: any) => {
    const { name, value, checked } = evt.target;

    setDispatchFirstData((prev) => ({
      ...prev,
      [name]: name === "fragile" ? checked : value,
    }));
    if (name === "senderPhone" && value !== "" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, senderPhone: "" }));
    }
    if (name === "senderAltPhone" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, senderPhone: "" }));
    }
    if (name === "senderName" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, senderName: "" }));
    }
    if (name === "senderEmail" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, senderEmail: "" }));
    }
    if (name === "recipientName" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, recipientName: "" }));
    }
    if (name === "recipientPhone" && value !== "" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, recipientPhone: "" }));
    }
    if (name === "quantity" && value !== "" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, quantity: "" }));
    }
    if (name === "cost" && value !== "" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, cost: "" }));
    }
    if (name === "itemDescription" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, itemDescription: "" }));
    }

    try {
      await createUserSchemaTwo(setInfoErrorMessages, true).validateAt(name, {
        [name]: value,
      });
    } catch (error: any) {
      if (error) {
      }
    }
  };

  useEffect(() => {
    if (
      Number(dispatchFirstData.cost) >=
        Number(dispatchFirstData?.importAddress) &&
      dispatchFirstData.file === ""
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        file: "Invoice is required",
      }));
    } else {
      setInfoErrorMessages((prev) => ({
        ...prev,
        file: "",
      }));
    }
  }, [
    dispatchFirstData?.cost,
    dispatchFirstData?.importAddress,
    dispatchFirstData.file,
  ]);

  const checkValidate = async () => {
    try {
      await createImportUserSchemaTwo(setInfoErrorMessages).validate(
        dispatchFirstData
      );
    } catch (err: any) {
      const errorMessage = err?.errors?.join(", ");
    }
  };

  const checkValidateTwo = async () => {
    try {
      await createUserSchemaTwo(setInfoErrorMessages, true).validate(
        dispatchFirstData
      );
    } catch (err: any) {
      const errorMessage = err?.errors?.join(", ");
    }
  };

  const handleCountryOption = (option: any) => {
    if (option === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        country: "Country is required",
      }));
    }
    if (option !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        country: "",
      }));
    }
    const selectedCountry = locationData.find(
      (location: any) => location?.country?.name === option
    );
    if (selectedCountry) {
      const { country, currency } = selectedCountry;
      setDispatchFirstData((prev) => ({
        ...prev,
        importLocationName: country?.name,
      }));
      setDispatchFirstData((prev) => ({
        ...prev,
        country: selectedCountry?.id,
      }));
      setDispatchFirstData((prev) => ({
        ...prev,
        importAddress: selectedCountry.proof,
      }));
      setQueryTwo(country?.name);
      setIsOpenTwo(false);
      setShowAddresses(selectedCountry);
      setCurrencyCode(currency?.code);
    } else {
      console.error(`Country '${option}' not found in locationData`);
    }
  };

  const handleItemOption = (option: any) => {
    if (option === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        item: "Item Category is required",
      }));
    }
    if (option !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        item: "",
      }));
    }
    const selectedItem = itemCategory.find(
      (item: any) => item?.name === option
    );
    setQuery(selectedItem?.name);
    setIsOpen(false);
    setDispatchFirstData((prev) => ({
      ...prev,
      item: selectedItem?.id,
    }));
  };

  useEffect(() => {
    if (dropOffData) {
      setDropOffZoneData(dropOffData.zones);
    }
  }, [dropOffData]);

  const getCostPriceData = async () => {
    setErrorCost("");
    setCostError("no");
    setOrderStatus(true);
    try {
      const requestPayload = {
        importLocationID: Number(dispatchFirstData?.country),
        destinationZoneID: Number(dispatchFirstData?.dropOffZone),
        weight: Number(dispatchFirstData.weight),
      };

      await getImportCostPrice(requestPayload).unwrap();
    } catch (error) {
      setCostError("Network Error! Please, try again!");
    }
  };

  useEffect(() => {
    if (applyReferral) {
      getCostReferralData();
    }
  }, [
    applyReferral,
    dispatchFirstData.weight,
    dispatchFirstData?.dropOffZone,
    dispatchFirstData?.country,
  ]);

  const getCostReferralData = async () => {
    setErrorCost("");
    setCostError("no");
    setOrderStatus(true);
    try {
      const requestPayload = {
        importLocationID: Number(dispatchFirstData?.country),
        destinationZoneID: Number(dispatchFirstData?.dropOffZone),
        weight: Number(dispatchFirstData.weight),
        referralCode: dispatchFirstData?.referralCode,
      };

      await getImportReferralPrice(requestPayload).unwrap();
    } catch (error) {
      setCostError("Network Error! Please, try again!");
    }
  };
  useEffect(() => {
    if (isCostError) {
      setErrorCost("Network Error! Please, try again!");
    }
  }, [isCostError]);

  useEffect(() => {
    if (showError) {
      setErrorCost(
        multipleData?.status.toLowerCase() === "error"
          ? multipleData?.description
          : ""
      );
    }
  }, [showError, multipleData?.status]);

  useEffect(() => {
    if (errorCost === "The data.0.orderNo has already been taken.") {
      setErrorCost("The Order Number has already been taken");
      setStep(1);
    }
  }, [errorCost]);
  useEffect(() => {
    if (
      dispatchFirstData.dropOffZone !== "" &&
      dispatchFirstData.country !== "" &&
      dispatchFirstData.weight !== ""
    ) {
      getCostPriceData();
    }
  }, [
    dispatchFirstData.dropOffZone,
    dispatchFirstData.country,
    dispatchFirstData.weight,
  ]);

  useEffect(() => {
    if (importLocations) {
      if (importLocations?.data?.data) {
        setLocationData(importLocations?.data?.data);
      }
    }
  }, [importLocations]);

  useEffect(() => {
    if (itemCategories) {
      if (itemCategories?.data) {
        setItemCategory(itemCategories?.data);
      }
    }
  }, [itemCategories]);

  const focusHandleDropOff = () => {
    if (queryThree === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "Drop off zone is required",
      }));
    }
    if (queryThree !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    }
  };

  const handleFileChange = (event: any) => {
    if (event.target === null) {
      console.error("No file selected");
      return;
    }
    const file = event.target.files[0];
    if (!file) {
      console.error("Failed to retrieve file");
      return;
    }
    if (file) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        file: "",
      }));
      setDispatchFirstData((prev) => ({
        ...prev,
        fileName: file?.type.split("/")[1],
      }));
      setDispatchFirstData((prev) => ({
        ...prev,
        fileNames: file?.name,
      }));
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event: any) => {
      const base64EncodedData = event.target.result;
      const base64Data = base64EncodedData.substring(
        base64EncodedData.indexOf(",") + 1
      );
      setDispatchFirstData((prev) => ({
        ...prev,
        file: base64Data,
      }));
    };
  };

  const handleOptionClickTwo = (option: any) => {
    if (option.location === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "Drop off zone is required",
      }));
    }
    if (option.location !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    }
    setQueryThree(option.location);
    setIsOpenThree(false);
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffZone: option.zoneid,
      dropOffLocationId: option.id,
      dropOffLocationName: option.location,
    }));
  };

  const getMultipleData = async () => {
    const requestPayload: IntlProps = {
      requestType: "import",
      data: [
        {
          requestType: "import",
          clientAddress: clientUserAddress,
          clientName: clientUserId,
          clientPhone: clientUserPhone,
          recipientName: dispatchFirstData?.recipientName,
          recipientPhone: dispatchFirstData?.recipientPhone,
          recipientEmail: dispatchFirstData?.recipientEmail,
          recipientAddress: dispatchFirstData?.dropOffAddress,
          clientEmail: dispatchFirstData?.senderEmail,
          thirdparty: "true",
          thirdparty_sendersName: dispatchFirstData.senderName,
          thirdparty_sendersPhone: dispatchFirstData.senderPhone,
          trackingId: dispatchFirstData?.trackingId,
          businessName: dispatchFirstData?.vendorName,
          itemCategory: Number(dispatchFirstData?.item),
          itemName: dispatchFirstData?.itemName,
          importLocation: Number(dispatchFirstData?.country),
          orderNo: dispatchFirstData?.orderNumber,
          dropzoneID: Number(dispatchFirstData?.dropOffZone),
          dropZoneName: zoneOptionThree,
          droplocationID: Number(dispatchFirstData.dropOffLocationId),
          droplocationName: dispatchFirstData.dropOffLocationName,
          uploadedProofExtension: dispatchFirstData?.fileName?.replace(
            "image/",
            ""
          ),
          quantity: Number(dispatchFirstData?.quantity),
          weight: Number(dispatchFirstData?.weight),
          itemDescription: dispatchFirstData?.itemDescription,
          orderDate: moment(new Date()).format("YYYY-MM-DD"),
          cost: Number(costData?.rate?.replace(/,/g, "")),
          paymentStatus: "ppc",
          paymentReference: "",
          discountedCost: referralData?.rate
            ? Number(referralData?.rate?.replace(/,/g, ""))
            : Number(costData?.rate?.replace(/,/g, "")),
          discount: costData?.discount,
          valueOfItem: Number(dispatchFirstData.cost),
          additionalNote: dispatchFirstData.additionalNote,
          uploadedProof: dispatchFirstData?.file,
          referralCode: dispatchFirstData?.referralCode,
          source: "Fez Website",
          fragile: dispatchFirstData.fragile || false,
        },
      ],
    };
    await orderMultiple(requestPayload)
      .unwrap()
      .then((data) => {
        console.log("data");
      })
      .catch((error: any) => {
        if (error?.data?.description) {
          toast.error(error?.data?.description, {
            className: "toast-error",
            progressClassName: "Toastify__progress-bar--error",
          });
        } else {
          toast.error("Network Error", {
            className: "toast-error",
            progressClassName: "Toastify__progress-bar--error",
          });
        }
        setCostError("Network Error! Please, try again!");
      });
  };

  useEffect(() => {
    if (
      dispatchFirstData?.senderEmail !== "" &&
      validateEmail(dispatchFirstData?.senderEmail) === null
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        senderEmail: "Invalid email format",
      }));
    } else if (
      dispatchFirstData?.senderEmail !== "" &&
      validateEmail(dispatchFirstData?.senderEmail) !== null
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        senderEmail: "",
      }));
    }
  }, [dispatchFirstData?.senderEmail]);

  useEffect(() => {
    if (dispatchFirstData.cost > dispatchFirstData.importAddress) {
      if (dispatchFirstData.file === "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          file: "invoice is required",
        }));
      }
    } else {
      setInfoErrorMessages((prev) => ({
        ...prev,
        file: "",
      }));
    }
  }, [
    dispatchFirstData.importAddress,
    dispatchFirstData.file,
    dispatchFirstData.cost,
  ]);

  useEffect(() => {
    if (
      dispatchFirstData?.recipientEmail !== "" &&
      validateEmail(dispatchFirstData?.recipientEmail) === null
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        recipientEmail: "Invalid email format",
      }));
    } else if (
      dispatchFirstData?.recipientEmail !== "" &&
      validateEmail(dispatchFirstData?.recipientEmail) !== null
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        recipientEmail: "",
      }));
    }
  }, [dispatchFirstData?.recipientEmail]);

  const onFinishStepOne = async () => {
    try {
      await checkValidate();
      if (dispatchFirstData?.orderNumber === "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          orderNumber: "Order number is required",
        }));
      } else if (dispatchFirstData?.orderNumber !== "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          orderNumber: "",
        }));
      }
      if (dispatchFirstData?.country === "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          country: "Country is required",
        }));
      } else if (dispatchFirstData?.country !== "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          country: "",
        }));
      }
      if (dispatchFirstData?.item === "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          item: "Item category is required",
        }));
      } else if (dispatchFirstData?.country !== "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          item: "",
        }));
      }
      if (dispatchFirstData?.dropOffZone === "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          dropOffZone: "Drop off zone is required",
        }));
      } else if (dispatchFirstData?.country !== "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          dropOffZone: "",
        }));
      }

      if (
        dispatchFirstData.dropOffZone !== "" &&
        dispatchFirstData.country !== "" &&
        dispatchFirstData.weight !== "" &&
        dispatchFirstData.weight !== "" &&
        dispatchFirstData.item !== "" &&
        dispatchFirstData.itemName !== "" &&
        dispatchFirstData.vendorName !== "" &&
        (dispatchFirstData.orderNumber !== "" ||
          dispatchFirstData.trackingId !== "") &&
        !isNaN(Number(dispatchFirstData.weight)) &&
        costError === "no" &&
        orderStatus !== false &&
        !isCostLoading
      ) {
        setStep(step + 1);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const onFinishStepTwo = async () => {
    try {
      await checkValidateTwo();
      if (
        dispatchFirstData.senderName !== "" &&
        dispatchFirstData.senderPhone !== "" &&
        !isNaN(Number(dispatchFirstData.senderPhone)) &&
        dispatchFirstData.senderEmail !== "" &&
        dispatchFirstData.recipientEmail !== "" &&
        dispatchFirstData.recipientName !== "" &&
        dispatchFirstData.recipientPhone !== "" &&
        !isNaN(Number(dispatchFirstData.recipientPhone)) &&
        dispatchFirstData.quantity !== "" &&
        !isNaN(Number(dispatchFirstData.quantity)) &&
        dispatchFirstData.itemDescription !== "" &&
        dispatchFirstData.cost !== "" &&
        infoErrorMessages.file === "" &&
        infoErrorMessages.recipientEmail === "" &&
        infoErrorMessages.senderEmail === "" &&
        !isNaN(Number(dispatchFirstData.cost))
      ) {
        setStep(step + 1);
      } else {
        console.log("err");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (termsChecked) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        terms: "",
      }));
    }
  }, [termsChecked]);

  const updateReferalCode = async (orderNos: any) => {
    try {
      const body: RefProps = {
        orderNo: orderNos ? orderNos : "",
      };
      if (referralData?.status === "00" && referralData?.rate !== "") {
        body.referralCode = dispatchFirstData.referralCode;
        await updateReference(body)
          .unwrap()
          .then((data) => {
            console.log("data");
          })
          .catch((error: any) => {
            if (error?.data?.description) {
              toast.error(error?.data?.description, {
                className: "toast-error",
                progressClassName: "Toastify__progress-bar--error",
              });
            } else {
              toast.error("Network Error", {
                className: "toast-error",
                progressClassName: "Toastify__progress-bar--error",
              });
            }
          });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onProceedToShipment = () => {
    if (termsChecked) {
      getMultipleData();
    } else {
      setInfoErrorMessages((prev) => ({
        ...prev,
        terms: "Terms and conditions is required!",
      }));
    }
  };

  useEffect(() => {
    if (step === 2 || step === 3) {
      scrollToTop();
    }
  }, [step]);

  useEffect(() => {
    if (
      multipleData &&
      Array.isArray(multipleData.orderNos) &&
      multipleData.orderNos[0]
    ) {
      updateReferalCode(multipleData.orderNos[0]);
      setShowSuccess(true);
    } else if (multipleData && dispatchFirstData?.orderNumber !== "") {
      setShowError(true);
    }
  }, [multipleData, dispatchFirstData?.orderNumber]);

  useEffect(() => {
    if (
      costData?.orderLocked === "YF" ||
      costData?.orderLocked === "YS" ||
      costData?.orderLocked === "Y" ||
      (costData?.status === "Error" && costData?.orderLocked === "Y") ||
      costData?.status === "Error" ||
      costData?.orderLocked === "D"
    ) {
      setShowOrderError(true);
    }
  }, [costData]);

  useEffect(() => {
    if (
      (costData?.orderLocked !== "YF" ||
        costData?.orderLocked !== "YS" ||
        costData?.orderLocked !== "Y") &&
      costData?.status === "Error" &&
      costData?.orderLocked !== "D"
    ) {
      setShowOrderErrorThree(true);
    }
  }, [costData]);

  useEffect(() => {
    if (
      costData?.orderLocked === "YF" ||
      costData?.orderLocked === "YS" ||
      costData?.orderLocked === "Y"
    ) {
      setShowOrderErrorOne(true);
    }
  }, [costData]);

  return (
    <PageLayout>
      <div>
        <ToastContainer position="top-right" />
      </div>
      {step === 1 && (
        <ImportStepOne
          setShowTerms={setShowTerms}
          errorCost={errorCost}
          queryTwo={queryTwo}
          handleCountryOption={handleCountryOption}
          locationData={locationData}
          infoErrorMessages={infoErrorMessages}
          showAddresses={showAddresses}
          showClipboard={showClipboard}
          copiedAdd={copiedAdd}
          dispatchFirstData={dispatchFirstData}
          itemCategory={itemCategory}
          query={query}
          isCostLoading={isCostLoading}
          onFinishStepOne={onFinishStepOne}
          handleChange={handleChange}
          isOpenThree={isOpenThree}
          setIsOpenThree={setIsOpenThree}
          setZoneOptionThree={setZoneOptionThree}
          queryThree={queryThree}
          setQueryThree={setQueryThree}
          focusHandleDropOff={focusHandleDropOff}
          handleOptionClickTwo={handleOptionClickTwo}
          handleItemOption={handleItemOption}
          filteredDropOffOptions={filteredDropOffOptions}
          setCopiedAdd={setCopiedAdd}
          setShowClipboard={setShowClipboard}
        />
      )}
      {step === 2 && (
        <ImportStepTwo
          setShowTerms={setShowTerms}
          errorCost={errorCost}
          infoErrorMessages={infoErrorMessages}
          dispatchFirstData={dispatchFirstData}
          handleChangeTwo={handleChangeTwo}
          step={step}
          setStep={setStep}
          currencyCode={currencyCode}
          onFinishStepTwo={onFinishStepTwo}
          handleFileChange={handleFileChange}
        />
      )}
      {step === 3 && (
        <ImportStepThree
          setShowTerms={setShowTerms}
          errorCost={errorCost}
          infoErrorMessages={infoErrorMessages}
          dispatchFirstData={dispatchFirstData}
          handleChange={handleChange}
          referralData={referralData}
          step={step}
          setStep={setStep}
          currencyCode={currencyCode}
          costData={costData}
          isReferralLoading={isReferralLoading}
          setApplyReferral={setApplyReferral}
          getCostReferralData={getCostReferralData}
          setTermsChecked={setTermsChecked}
          isMultipleLoading={isMultipleLoading}
          onProceedToShipment={onProceedToShipment}
        />
      )}
      {showSuccess && (
        <DashboardModalAction
          contentTitle={"Success! ✅ "}
          content={`Your order has been created.
          Expect an email notification once we receive the package. 
          Here is your Order ID 
          `}
          btnContent="Close"
          trackNo={
            multipleData &&
            multipleData.orderNos &&
            Array.isArray(multipleData.orderNos) &&
            multipleData.orderNos[0]
          }
          onClick={() => window.location.reload()}
        />
      )}

      {showOrderError && (
        <ShowOrderLockYF onClick={() => setShowOrderError(false)} />
      )}

      {showOrderErrorOne && (
        <ShowOrderErrorandD onClick={() => setShowOrderErrorOne(false)} />
      )}

      {showOrderErrorThree && (
        <ShowErrorandYS onClick={() => setShowOrderErrorThree(false)} />
      )}
      {showTerms && (
        <ShowTermsModal
          onClick={() => setShowTerms(false)}
          terms={devTerms}
          id="formattedText"
        />
      )}
      {multipleError && (
        <ErrorModal
          errorMessage={"Network Error, Please, try again! "}
          onClick={() => location.reload()}
        />
      )}
    </PageLayout>
  );
};

export default Import;
