import { string, number } from "yup";

export const validateStringField = (
    fieldName: string,
    errorMessages: Record<string, string>,
    setError: (callback: (prev: any) => any) => void
  ) =>
    string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setError((prev) => ({
            ...prev,
            [fieldName]: errorMessages[fieldName],
          }));
          throw new TypeError(errorMessages[fieldName]);
        }
        return value;
      })
      .required(errorMessages[fieldName]);
  
  export const validateNumberField = (
    fieldName: string,
    errorMessages: Record<string, string>,
    setError: (callback: (prev: any) => any) => void
  ) =>
    number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue) || originalValue === "") {
          setError((prev) => ({
            ...prev,
            [fieldName]: errorMessages[fieldName],
          }));
          throw new TypeError(errorMessages[fieldName]);
        }
        return value;
      })
      .required(errorMessages[fieldName])
      .positive()
      .integer();
  
  export const validateEmailField = (
    fieldName: string,
    errorMessages: Record<string, string>,
    setError: (callback: (prev: any) => any) => void,
    condition: boolean = true
  ) =>
    string()
      .transform((value, originalValue) => {
        if (originalValue === "" && condition) {
          setError((prev) => ({
            ...prev,
            [fieldName]: errorMessages[fieldName],
          }));
          throw new TypeError(errorMessages[fieldName]);
        }
        return value;
      })
      .email("Invalid email format")
      .required(errorMessages[fieldName]);
  