export const initialValues = {
  pickUpAddress: "",
  pickUpZone: "",
  pickUpLocationId: "",
  pickUpLocationName: "",
  dropOffAddress: "",
  dropOffZone: "",
  dropOffLocationId: "",
  dropOffLocationName: "",
  pickUpDate: "",
  senderName: "",
  senderPhone: "",
  senderAltPhone: "",
  senderEmail: "",
  recipientName: "",
  recipientEmail: "",
  recipientPhone: "",
  itemDescription: "",
  quantity: "",
  referenceId: "",
  weight: "",
  cost: "",
  additionalNote: "",
  dropOffLocker: "",
};

export const initialDispatchValues = {
  pickUpAddress: "",
  pickUpZone: "",
  pickUpLocationId: "",
  pickUpLocationName: "",
  dropOffAddress: "",
  dropOffZone: "",
  dropOffLocationId: "",
  dropOffLocationName: "",
  deliveryType: "bike",
  deliveryMode: "bike",
  dropOffId: 0,
  pickUpDate: "",
  senderName: "",
  senderPhone: "",
  senderAltPhone: "",
  senderEmail: "",
  recipientEmail: "",
  recipientName: "",
  recipientPhone: "",
  itemDescription: "",
  quantity: "",
  referenceId: "",
  weight: "",
  cost: "",
  additionalNote: "",
  referralCode: "",
  dropOffLocker: "",
  dropOffLockerAddress: "",
  fragile: false,
};

export const importInitialValues = {
  pickUpAddress: "",
  pickUpZone: "",
  orderNumber: "",
  trackingId: "",
  dropOffLocationId: "",
  dropOffZone: "",
  dropOffLocationName: "",
  importAddress: "",
  importLocationName: "",
  localGovernmentArea: "",
  country: "",
  senderName: "",
  vendorName: "",
  dropOffAddress: "",
  itemName: "",
  senderPhone: "",
  senderAltPhone: "",
  senderEmail: "",
  recipientEmail: "",
  recipientName: "",
  recipientPhone: "",
  itemDescription: "",
  file: "",
  fileName: "",
  fileNames: "",
  quantity: "",
  referenceId: "",
  weight: "",
  item: "",
  cost: "",
  additionalNote: "",
  referralCode: "",
  fragile: false,
};
export const importInitialDispatchValues = {
  pickUpAddress: "",
  pickUpZone: "",
  orderNumber: "",
  trackingId: "",
  dropOffZone: "",
  importAddress: "",
  pickUpDate: "",
  dropOffAddress: "",
  senderName: "",
  vendorName: "",
  localGovernmentArea: "",
  itemName: "",
  senderPhone: "",
  senderAltPhone: "",
  senderEmail: "",
  recipientName: "",
  recipientEmail: "",
  recipientPhone: "",
  itemDescription: "",
  quantity: "",
  referenceId: "",
  weight: "",
  file: "",
  cost: "",
  country: "",
  item: "",
  terms: "",
  additionalNote: "",
};
